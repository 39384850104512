import company from '../../assets/companyImage1.jpeg';
import company2 from '../../assets/companyImage2.jpeg';
import company3 from '../../assets/companyImage3.jpeg';
import company4 from '../../assets/companyImage4.jpeg';
import company5 from '../../assets/companyImage5.jpeg';
import company6 from '../../assets/companyImage6.jpeg';
import company7 from '../../assets/companyImage7.jpeg';
import company8 from '../../assets/companyImage8.jpeg';
import map from '../../assets/companyMap.png';
import companyMain from '../../assets/digital.mp4';
import arrow from '../../assets/arrow.png';
import styles from '../../css/company.module.css';
import moment from 'moment';


const Company_isMobileL = ({position,title1,title2,title3, mobile_text1, mobile_text2, companyText3, companyText4, companyText5, companyText6, companyText7, companyText8, companyText14, companyText15 ,companyText19, companyText20, companyText21, companyText22, mobile_text3 , news1, newsList, blockNews, nextArrow, prevArrow, clickNewsDetail, clickNews})=>{
    return(
        <>
        <div style={{width:'auto',height:'auto'}}>
            <div style={{ width: '100%', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
                <div style={{ width: '100%',backgroundColor:'',position:'relative',overflow:'hidden' ,zIndex:1}}>
                    <video muted loop autoPlay  src={companyMain} style={{width:'100%', zIndex:-1, height:'100%'}}/>
                    <div style={{position:'absolute', width: '100%', backgroundColor: '', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', color: 'black', zIndex:1, top:'150px',left:'230px',transform: `translate(-50%, -50%)`}}>
                        <span ref={title1} style={{ fontSize: '18px', fontWeight: 'bolder' , color:'white', opacity:0, transition:'all 1s ease', transitionDelay:'0.5s'}}>GLINS</span>
                        <span ref={title2} style={{ fontSize: '18px', fontWeight: 'bolder' , color:'white', opacity:0, transition:'all 1s ease' , transitionDelay:'1s'}}>UNIVERSE</span>
                        <span ref={title3} style={{ fontSize: '18px', fontWeight: 'bolder' , color:'white', opacity:0, transition:'all 1s ease', transitionDelay:'2s'}}>새로운 농업 솔루션 전문 기업</span>
                    </div>
                </div>
            </div>
        </div>
        <div style={{width:'auto',heigt:'auto'}}>
            <div style={{width:'100%',backgroundColor:'',display:'flex', marginTop:'10%', position:'relative', justifyContent:'flex-end', alignItems:'flex-end'}}>
                <div style={{flex:'display', flexDirection:'column', display:''}}>
                    <div style={{width:'100%', backgroundColor:'',display:'flex',justifyContent:'center',alignItems:'flex-start',overflow:'hidden'}}>
                        <div style={{width:'100%',display:'flex', marginLeft:'10%'}}>
                            <img src={company} width={'100%'}/>
                        </div>
                        <div style={{width:'100%',display:'flex',marginLeft:'1%'}}>
                            <img src={company2} width={'100%'}/>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{width:'100%', backgroundColor:'',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',fontWeight:'bold', position:'relative'}}>
                <div style={{ width:'100%',backgroundColor:'',fontSize:'18px',marginBottom:'6%',marginTop:'4%', display:'flex', flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-start',overflow:'hidden'}}>
                    <span  ref={mobile_text1}  style={{padding:5, transition:'all 1s ease', opacity:0, transitionDelay:'0.5s'}}>첨단 기술의 융.복합으로 지엘아이엔에스는</span>
                    <span  ref={mobile_text2}  style={{paddingLeft:5, paddingBottom:5, transition:'all 1s ease', opacity:0, transitionDelay:'1s'}}> 미래 농업시장을 선도해 나갑니다.</span>
                </div>

                <div  style={{width:'100%',backgroundColor:'',display:'flex',flexDirection:'column',justifyContent:'center', alignItems:'flex-start', fontWeight:'lighter',marginBottom:'3%',fontSize:'15px',overflow:'hidden'}}>
                    <span ref={mobile_text3} style={{paddingLeft:10, paddingRight:10, opacity:0, transition:'all 1s ease',transitionDelay:'1.5s'}}>
                    스마트농업, 정밀농업, 무인농업 등
                    다양한 농업환경에 최첨단 기술을 도입하여
                    농업의 편리함,효율성,생산성을 극대화하고 있습니다.

                    우리는 “글린스 유니버스”라는 농업 솔루션 생태계를 만들어 농부들에게 최적화된 기술과 성능을 제공하며
                    농업의 미래를 선도해 나갑니다.
                    </span>
                </div>
            </div>
            
            
            <div style={{width:'100%',backgroundColor:'',display:'flex', position:'relative',justifyContent:'flex-start', alignItems:'flex-start',marginTop:'20%'}}>
                <div style={{width:'100%', backgroundColor:'',display:'flex',justifyContent:'center',alignItems:'center', overflow:'hidden'}}>
                    <div style={{width:'100%',display:'flex'}}>
                        <img src={company4} width={'100%'}/>
                    </div>
                    <div style={{width:'100%',display:'flex', marginLeft:'1%'}}>
                        <img src={company5} width={'100%'}/>
                    </div>
                </div> 
            </div>
            <div ref={companyText3} style={{width:'100%', background:'',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start',fontWeight:'bold',position:'relative',marginBottom:'10%'}}>
                <div style={{width:'100%',backgroundColor:'',fontSize:'15px',marginBottom:'10%',marginTop:'10%', fontWeight:'normal',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start', overflow:'hidden', opacity: (position-200)/100, transition : 'all 1s ease'}}>
                    <span style={{paddingLeft:10, paddingRight:10}}>지엘아이엔에스 기술연구소는 농업 선행기술 확보 및 디지털 역량 강화를 통해 농업 패러다임의 변화와 혁신을 이끌고 있습니다. 
                    AI/빅데이터, ICT 융·복합 기술 및 제조업 생산방식 농업 기술 등 스마트기술을 적용해 지속적으로 혁신을 추구하고 있으며, 차별화된 기술을 제공해 기술 경쟁력을 높이고 있습니다. 
                    유사 프로젝트의 성공·실패 사례를 분석하고, 농업 현장의 품질 및 생산성 향상이 가능한 기술 솔루션을 선제적으로 제공하고 있습니다.
                    </span>
                </div>
            </div>
        </div>
        <div style={{width:'auto',height:'auto'}}>
            <div style={{width:'100%',position:'relative', display:'flex', justifyContent:'center',alignItems:'center', backgroundColor:'black'}}>
                <div style={{width:'1050px',backgroundColor:'', display:'flex',flexDirection:'column',justifyContent:'flex-start', alignItems:'flex-start'}}>
                    <div ref={companyText4} style={{width:'100%',backgroundColor:'',display:'flex',flexDirection:'column',color:'white',fontSize:'20px',fontWeight:'bolder',marginTop:'200px', opacity:(position- 710)/100}}>
                        <span style={{padding:10}}>최첨단 기술을 바탕으로 <br/> 농업의 세계를 무대로 무한한 가능성에 <br/>도전하겠습니다</span>
                    </div>
                    <div ref={companyText5} style={{width:'100%',backgroundColor:'',display:'flex',flexDirection:'column',color:'white',marginTop:'100px',fontWeight:'bolder', opacity:(position- 750)/100, transition:'all 1s ease'}}>
                        <span  style={{fontSize:'20px', padding:15}}>지속가능한 경제가치 창출</span>
                        <span  style={{fontSize:'15px',marginTop:'5px', padding:15}}>
                        차별화된 기술력과 검증된 사업 역량을 기반으로 농업의 포트폴리오를 다각화하고 
                        전 영역을 아우르는 Total Solution Creator로 도약하여 미래형 농업산업을 선도하고자 합니다.
                        </span>
                    </div>
                    <div ref={companyText6} style={{width:'100%',backgroundColor:'',display:'flex',flexDirection:'column',color:'white',marginTop:'100px',fontWeight:'bolder', opacity:(position- 843)/100, transition:'all 1s ease'}}>
                        <span  style={{fontSize:'20px', padding:15}}>미래 기술·고객 서비스 혁신</span>
                        <span style={{fontSize:'15px',marginTop:'5px', padding:15}}>
                        미래 Transformation 전략에 부합하는 농업의 핵심 분야를 중심으로 
                        독보적인 기술 개발과 기술 주도형 사업 추진 등 모든 역량을 집중적으로 투입하고 있습니다.
                        </span>
                    </div>
                    <div ref={companyText7} style={{width:'100%',backgroundColor:'',display:'flex',flexDirection:'column',color:'white',marginTop:'100px',fontWeight:'bolder', opacity:(position- 1117)/100, transition:'all 1s ease'}}>
                        <span  style={{fontSize:'20px', padding:15}}>지역사회 가치 창출</span>
                        <span style={{fontSize:'15px',marginTop:'5px', padding:15}}>
                        ‘상생협력을 통한 동반성장’을 목표로 고도화된 공급망 관리 프로세스를 구축하고 있으며, 
                        소통과 협력을 통해 지속가능한 밸류체인 구축에 힘쓰고 있습니다.
                        </span>
                    </div>
                    <div ref={companyText8} style={{width:'100%',backgroundColor:'',display:'flex',flexDirection:'column',color:'white',marginTop:'100px',fontWeight:'bolder', opacity:(position- 1376)/100, transition:'all 1s ease'}}>
                        <span style={{fontSize:'20px', padding:15}}>통합적 리스크 관리</span>
                        <span style={{fontSize:'15px',marginTop:'5px', padding:15}}>
                        재무·비재무적 리스크의 통합적인 관리를 통해 발생 가능한 위험 요소에 대해 선제적으로 대응하여 사업의 안정성 확보를 위해 최선을 다하고 있으며, 
                        밸류체인 전체에서 발생할 수 있는 리스크 또한 선제적으로 분석하여 부정적 영향을 최소화하기 위해 노력하고 있습니다.
                        </span>
                    </div>  
                </div>
            </div>
        </div>
        
        <div style={{width:'auto',height:'auto'}}>
            <div style={{width:'100%',position:'relative', display:'flex', justifyContent:'center',alignItems:'center', backgroundColor:'black'}}>
                <div  style={{width:'100%', height:'100%',backgroundColor:'', display:'flex',flexDirection:'column',justifyContent:'center', alignItems:'center',marginTop:'300px'}}>
                    <div ref={companyText14}  style={{width:'100%',display:'flex',justifyContent:'flex-start',alignItems:'center',backgroundColor:''}}>
                        <span style={{color:'white',fontSize:'50px', fontWeight:'bolder',marginLeft:'20px'}}>뉴스</span>
                    </div>
                    <div style={{width:'100%', height:'500px', backgroundColor:'', display:'flex', flexDirection:'column'}}>
                        <div  ref={news1} style={{width:'100%',height:'100%',backgroundColor:''}}>
                            {newsList.length > 0 &&
                            <>
                                <div style={{width:'100%', height:'630px', backgroundColor:'', display:'flex', flexDirection:'column'}}>
                                    <div  ref={news1} style={{width:'100%', height:'100%',backgroundColor:''}}>
                                        {blockNews.map((news,index)=>{
                                            return(
                                                <div onClick={()=>{clickNewsDetail(news.news_no)}} className={styles.newsBox} ref={companyText15} style={{width:'100%', height:'130px', backgroundColor:'#1e1e1e',display:'flex', flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-start', marginTop:'10px'}}>
                                                    {/* <Link to='/news1' style={{width:'100%', height:'100%'}}>
                                                        
                                                    </Link> */}
                                                    <div style={{width:'100%',height:'30%', backgroundColor:'', display:'flex', justifyContent:'flex-start',alignItems:'center'}}>
                                                        <span style={{color:'white',fontSize:'20px', padding:30}}>{moment(news.date).format('YYYY년MM월DD일')}</span> 
                                                    </div>
                                                    <div style={{width:'100%',height:'70%', backgroundColor:'', display:'flex', justifyContent:'flex-start',alignItems:'center'}}>
                                                        <span style={{color:'white',fontSize:'30px', fontWeight:'bolder', padding:30}}>{news.news_title}</span> 
                                                    </div>
                                                </div>
                                            )
                                            
                                        })}
                                    </div>
                                    <div style={{width:'100%', height:'100%',backgroundColor:'',marginTop:'10px', display:'flex', flexDirection:'row', justifyContent:'flex-end',alignItems:'flex-start'}}>
                                        {prevArrow &&
                                            <img className={styles.arrow} src={arrow} width={'30px'} style={{transform:`scaleX(-1)`}} onClick={()=>{clickNews('before')}}/>
                                        }
                                        {nextArrow &&
                                            <img className={styles.arrow} src={arrow} width={'30px'} onClick={()=>{clickNews('next')}}/>
                                        }
                                        
                                    
                                    </div>
                                </div>  
                            </>
                            }
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </div>
        <div style={{width:'auto',height:'auto'}}>
            <div style={{width:'100%',position:'relative', display:'flex', justifyContent:'center',alignItems:'center',flexDirection:'column', backgroundColor:'black'}}>
                <div style={{width:'100%',backgroundColor:'', display:'flex',flexDirection:'column',justifyContent:'flex-start', alignItems:'flex-start',marginTop:'300px'}}>
                    <div style={{width:'100%',display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <img src={map} width={'100%'} style={{}}/>
                    </div>
                </div>
                <div style={{width:'100%',display:'flex',justifyContent:'center', alignItems:'center', backgroundColor:''}}>
                    <div style={{width:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',backgroundColor:'', marginBottom:'100px',padding:10}}>
                        <div ref={companyText19} style={{width:'100%',height:'100px',backgroundColor:'', color:'white',display:'flex', flexDirection:'column',justifyContent:'flex-start', alignItems:'flex-start',marginTop:'3%'}}>
                            <span style={{borderBottom: '2px solid #FFF', borderBottomWidth:1, width:'100%', height:'10%', display:'flex', margin:0}}/>
                            <span style={{fontSize:'20px', fontWeight:'bold',marginTop:'1%',padding:5}}>주소</span>
                            <span style={{fontSize:'15px', fontWeight:'normal',marginTop:'1%',paddingLeft:5,paddingTop:1}}>대전광역시 대덕구 오정로23번길 54-11, 4층 (본사)</span>
                        </div>
                        <div ref={companyText20} style={{width:'100%',height:'80px',backgroundColor:'',color:'white' ,display:'flex', flexDirection:'column',justifyContent:'flex-start', alignItems:'flex-start',marginTop:'3%'}}>
                            <span style={{borderBottom: '2px solid #FFF', borderBottomWidth:1, width:'100%', height:'10%', display:'flex', margin:0}}/>
                            <span style={{fontSize:'20px', fontWeight:'bold',marginTop:'1%',padding:5}}>전화번호</span>
                            <span style={{fontSize:'15px', fontWeight:'normal',marginTop:'1%',paddingLeft:5,paddingTop:1}}>042-933-9685</span>
                        </div>
                        <div ref={companyText21} style={{width:'100%',height:'80px',backgroundColor:'', color:'white' ,display:'flex', flexDirection:'column',justifyContent:'flex-start', alignItems:'flex-start',marginTop:'3%'}}>
                            <span style={{borderBottom: '2px solid #FFF', borderBottomWidth:1, width:'100%', height:'10%', display:'flex', margin:0}}/>
                            <span style={{fontSize:'20px', fontWeight:'bold',marginTop:'1%',padding:5}}>팩스번호</span>
                            <span style={{fontSize:'15px', fontWeight:'normal',marginTop:'1%',paddingLeft:5,paddingTop:1}}>070-7543-1354</span>
                        </div>
                        <div ref={companyText22} style={{width:'100%',height:'80px',backgroundColor:'',color:'white' , display:'flex', flexDirection:'column',justifyContent:'flex-start', alignItems:'flex-start',marginTop:'3%'}}>
                            <span style={{borderBottom: '2px solid #FFF', borderBottomWidth:1, width:'100%', height:'10%', display:'flex', margin:0}}/>
                            <span style={{fontSize:'20px', fontWeight:'bold',marginTop:'1%',padding:5}}>메일</span>
                            <span style={{fontSize:'15px', fontWeight:'normal',marginTop:'1%',paddingLeft:5,paddingTop:1}}>ceo@corp-gl.com</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Company_isMobileL;