import { useEffect, useState, useRef, createRef, cloneElement} from 'react';
import logo from '../../assets/glins.png';
import { FloatingLabel,Form,Button, Dropdown,Table, Modal } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import AdminHeader from './layout/ad_header';


export default function Ad_main(){

    const fileUpload = useRef();
    const [title , setTitle] = useState('');
    const [cntn , setCntn] = useState('');
    const [photoList , setPhotoList] = useState([]);    //사진파일명
    const [photoData , setPhotoData] = useState([]);    //사진데이터


    const clickInsertPhoto =  async()=>{
        if(photoList.length >= 3){
            alert('이미지는 최대 3개까지만 가능합니다.');
            return;
        }

        fileUpload.current.click();
    }

    const clickInsertNews = async()=>{
        const formData = new FormData();

        if(title === "" || cntn === ""){
            alert('제목과 내용은 필수입니다.');
            return;
        }

        /* 1. 뉴스 기사 등록 */
        const insertNews = await fetch('http://192.168.0.13:3006/news/insertNews',{
            method:'POST',
            headers : {'Content-Type' : 'application/json'},
            body : JSON.stringify({
                title : title,
                cntn : cntn
            })
        });

        if(insertNews.ok){ // 뉴스등록이 성공하면 사진저장
            const news_no = await insertNews.json();
            formData.append('json',JSON.stringify({no : news_no[0].news_no}));
            /* 이미지 저장 */
            for(let a=0; a<photoData.length; a++){
                formData.append('file',photoData[a]);
                const insertPhoto = await fetch('http://192.168.0.13:3006/news/insertPhoto',{
                    method : "POST",
                    body : formData
                });
                formData.delete('file');
            }

            setTitle('');
            setCntn('');
            setPhotoList([]);
            alert('뉴스등록이 완료되었습니다.');

        }else{
            alert('오류가 발생하였습니다. 다시시도해주세요');
            return;
        }  
       
    }

    const clickDeleteImage = async(index)=>{
        const newList = [...photoList];
        const newData = [...photoData];

        newData.splice(index,1);
        newList.splice(index,1);

        setPhotoList(newList);
        setPhotoData(newData);
    }
   
    const clickFile = async(e)=>{
        const file = e.target.files[0];
        if(!file.type.includes('image')){
            alert('이미지만 첨부가능합니다.');
            return;
        }

        /* 사진파일 넣기 */
        let list = photoList;
        list.push(file.name);
        // setPhotoList(prevList=>[...prevList, file.name])
        setPhotoList(prevList=>[...prevList])
        list = [];
        // setPhotoList(list);  // 여기서 바로 리셋되어야하는데 왜 안되는걸까요..???

        /* 파일데이터 넣기 */
        let photo_data = photoData;
        photo_data.push(file);
        setPhotoData(prevPhotoData=>[...prevPhotoData]);
    }


    return(
        <div style={{width:'auto', height:'auto'}}>
            <div style={{width:'100%', height :'100vh', display:'flex'}}>
                <AdminHeader/>
                <div style={{width:'100%',height:'auto',paddingTop:'80px', backgroundColor:'',display:'flex',justifyContent:'center', alignItems:'center'}}>
                    <div style={{width :'80%',height:'100%', backgroundColor:''}}>
                        <div style={{width:'100%', height:'10%',backgroundColor:'',marginTop:'30px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <div style={{width:'80%',height:'100%',backgroundColor:'', display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                                <span style={{marginRight:'40px', fontSize:'20px'}}>제목</span>
                                <input type={'text'} value={title} onChange={(e)=>{setTitle(e.target.value)}} style={{borderRadius:5, width:'500px' ,height:'30px'}}/>
                                <div style={{width:'30%',height:'100%', backgroundColor:'',marginLeft:'10px', display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                                    <Button variant='success' onClick={clickInsertPhoto} style={{width:'100px', height:'30px', backgroundColor:'', borderRadius:5,marginRight:'10px'}}>사진첨부</Button>
                                    <input ref={fileUpload} type='file' onChange={clickFile} id="파일첨부" hidden/>
                                    <Button variant='success' onClick={clickInsertNews} style={{width:'100px', height:'30px', backgroundColor:'', borderRadius:5}}>뉴스등록</Button>
                                </div>
                            </div>
                        </div>
                        <div style={{width:'100%', height:'80%',backgroundColor:'',marginTop:'30px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <div style={{width:'80%',height:'100%',backgroundColor:'', display:'flex', justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'column'}}>
                                <div style={{width:'50%', height:'20%', backgroundColor:'white',marginBottom:'30px', borderRadius:10, border:'1px solid gray'}}>
                                    {photoList.length <= 0 ?
                                        <div style={{width:'100%', height:'100%',display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <span style={{color : 'gray'}}>이미지 첨부는 최대 3개까지 가능합니다.</span>
                                        </div>
                                    :
                                        <div style={{width:'100%',height:'100%',display:'flex', justifyContent:'flex-start',alignItems:'center', flexDirection:'column'}}>
                                            {photoList.map((photo, index)=>{
                                                return(
                                                    <div key={index} style={{width:'100%',height:'auto', backgroundColor:'', display:'flex',justifyContent:'space-between', alignItems:'center'}}>
                                                        <span style={{color:'black',padding:10}}>{photo}</span>
                                                        <Button variant='success' onClick={()=>{clickDeleteImage(index)}} style={{width:'50px', height:'27px', backgroundColor:'', borderRadius:5,marginRight:'20px'}}>삭제</Button>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    }
                                </div>
                                <textarea style={{width:'80%', height:'70%', borderRadius:10,padding:5}} value={cntn} placeholder="내용을 입력하세요" onChange={(e)=>{setCntn(e.target.value)}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}