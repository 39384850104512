import { useEffect, useState, useRef, createRef, cloneElement , useCallback, useMemo} from 'react';
import styles from '../css/main.module.css';
import main_1 from '../assets/main_1.jpg';
import main_2 from '../assets/main_2.jpg';
import main_3 from '../assets/main_3.jpg';
import main_4 from '../assets/main_4.jpg';
import mianVideo from '../assets/main.mp4';
import { useMediaQuery } from 'react-responsive';


import Footer from '../layout/footer';
import Header from '../layout/header';
import Main_isFold from './main/main_isFold';
import Main_isTablet from './main/main_isTablet';
import Main_isLapTopL from './main/main_isLapTopL';
import Main_default from './main/main_default';
import Main_isMobileL from './main/main_isMobileL';


export default function Main(props){
    
    /* responsive area ==== start ==== */
    const isTablet = useMediaQuery({query:'(max-width:768px)'});
    const isMobileS =  useMediaQuery({query:'(max-width:320px)'});
    const isMobileM = useMediaQuery({query:'(max-width:375px)'});
    const isMobileL = useMediaQuery({query:'(max-width:425px)'});
    const isLapTop = useMediaQuery({query:'(max-width:1024px)'});
    const isLapTopL = useMediaQuery({query:'(max-width:1440px)'});
    const is4K = useMediaQuery({query:'(max-width:2560px)'});
    const isFold = useMediaQuery({query:'(max-width:280px)'});
    /* responsive area ==== end ==== */





    const [position , setPosition] = useState(0);
    const [headerVisible , setHeaderVisible] = useState(true);

    let prevScroll = 0;

    const mainText1 = useRef();
    const mainText2 = useRef();
    const mainText3 = useRef();
    const mainText4 = useRef();
    const scmainText1 = useRef();
    const scmainText2 = useRef();
    const scmainImage = useRef();
    const tcmainText1 = useRef();
    const tcmainText2 = useRef();

    useEffect(()=>{
        window.scrollTo(0,0)
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    },[]);

    
    const onScroll = ()=>{
        const currentScroll = window.scrollY;
        setPosition(window.scrollY);

        if(currentScroll < prevScroll){
            setHeaderVisible(true);
        }else{
            setHeaderVisible(false);
        }
        prevScroll = currentScroll;
    }

    return(
        <div className={styles.fullcContainer}>
            {headerVisible && <Header/>}
            {/* <Header/> */}
            {/* mainpage_1 start */}
            {isFold ? 
                <Main_isFold position={position} mainText1={mainText1} mainText2={mainText2} mainText3={mainText3} mainText4={mainText4} scmainText1={scmainText1} scmainText2={scmainText2} tcmainText1={tcmainText1} tcmainText2={tcmainText2} scmainImage={scmainImage}/>
            :
                <>
                {isMobileL ? 
                    <Main_isMobileL position={position} mainText1={mainText1} mainText2={mainText2} mainText3={mainText3} mainText4={mainText4} scmainText1={scmainText1} scmainText2={scmainText2} tcmainText1={tcmainText1} tcmainText2={tcmainText2}/>
                :
                    <>
                    {isTablet ? 
                        <Main_isTablet position={position} mainText1={mainText1} mainText2={mainText2} mainText3={mainText3} mainText4={mainText4} scmainText1={scmainText1} scmainText2={scmainText2} tcmainText1={tcmainText1} tcmainText2={tcmainText2} scmainImage={scmainImage}/>
                    :
                        <>
                        {isLapTopL ? 
                            <Main_isLapTopL position={position} mainText1={mainText1} mainText2={mainText2} mainText3={mainText3} mainText4={mainText4} scmainText1={scmainText1} scmainText2={scmainText2} tcmainText1={tcmainText1} tcmainText2={tcmainText2}/>
                        :
                            <Main_default position={position} mainText1={mainText1} mainText2={mainText2} mainText3={mainText3} mainText4={mainText4} scmainText1={scmainText1} scmainText2={scmainText2} tcmainText1={tcmainText1} tcmainText2={tcmainText2}/>
                        }
                        </>
                    }
                    </>
                }
                </>
            }
            <Footer/>
        </div>
    )
}