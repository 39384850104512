import styles from '../../css/tech.module.css';
import techStyle from '../../css/tech.module.css';
import test2 from '../../assets/test2.jpeg';
import tracter from '../../assets/tracter.jpeg';
import mobileBattery from '../../assets/mobileBattery.jpeg'
import techLast from '../../assets/techLast.jpeg';
import appDownload from '../../assets/appDownload.png';
import appImage2 from '../../assets/appImage2.png';
import tech_tractor from '../../assets/tech_tractor.jpg';


const Tech_isLapTopL = ({position, techTruckImage, techTruckText,techTruckText2,batteryText1,batteryText2, batteryBox1,batteryBox2, batteryBox3, batteryBox4,appText1 , appText2, appText3, appText4, techText})=>{
    return(
        <>
            {/* first page - start */}
            <div className={styles.fullcContainer} >
                <div className={styles.sticky_container} >
                    <div className={styles.sticky}>
                        <div className={styles.slide_container}>
                            <div className={styles.slide}>
                                <div style={{width:'100%', height:'100%', backgroundColor:'black',position:'relative', paddingTop:'80px'}}>
                                    <div style={{width:'100%',height:'100%',backgroundColor:'',position:'absolute',display:'flex', justifyContent:'center',alignItems:'center', overflow:'hidden'}}>
                                        <div style={{width:'1500px',height:'1000px',backgroundColor:'', position:'relative', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div ref={techTruckImage} className={styles.techTruckImage}>
                                                <img alt="" src={tracter} width={'85%'} style={{opacity:1}}/>
                                            </div>
                                            
                                            <div ref={techTruckText} style={{position:'absolute', width:'850px', height:'25%', top:'25%',left:'20%',backgroundColor:'',display:'flex',justifyContent:'flex-start',alignItems:'center',fontSize:'250px',fontWeight:'bolder',color:'white',zIndex:1}}>
                                                <span className={techStyle.techText} style={{marginLeft:'90px'}}>{techText}</span>
                                            </div>
                                            <div  ref={techTruckText2}style={{position:'absolute',width:'50%', height:'40%',backgroundColor:'',top:'450px', left:'10px', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'column', opacity:0}}>
                                                <div style={{width:'90%',height:'100%',backgroundColor:'', display:'flex',flexDirection:'column'}}>
                                                    
                                                    <div style={{width:'100%',display:'flex',flexDirection:'column',justifyContent:'flex-start', alignItems:'flex-start'}}>
                                                        <span style={{color:'white', fontWeight:'bolder', fontSize:'40px'}}>스마트 농업 지능형 농업 기계 솔루션</span>
                                                        <span style={{color:'white', fontWeight:'bolder', fontSize:'20px'}}>디바이스를 통한 간편한 첨단 기술 보급은</span>
                                                        <span style={{color:'white', fontWeight:'bolder', fontSize:'20px'}}>농업 기계 제조와 관리를 더욱 편리하게 만듭니다.</span>
                                                    </div>
                                                    
                                                </div>
                                            </div>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* techpage_1 end */}
            {/* techpage_2 start - 이후 추가 될 영역 */}
            {/* <div style={{width:'auto',height:'auto'}}>
                <div style={{width:'100%',position:'relative', display:'flex', justifyContent:'center',alignItems:'center', backgroundColor:'white'}}>
                    <div style={{width:'1040px',display:'flex', justifyContent:'space-between',alignItems:'flex-start',flexWrap:'wrap', marginTop:'200px', marginBottom:'250px'}}>
                        <div style={{width:'50%',backgroudColor:'', display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-start'}}>
                            <div ref={boxImage1} style={{opacity:(position-2317)/200}}>
                                <img src={image1} />
                            </div>
                            <div ref={boxImage3} style={{marginTop:'20px',opacity:(position-2776)/200}}>
                                <img src={image3} />
                            </div>
                        </div>
                        <div style={{width:'50%',backgroudColor:'',display:'flex' ,justifyContent:'flex-end',alignItems:'flex-end'}}>
                            <div ref={boxImage2} style={{backgroundColor:'',opacity:(position-2317)/200}}>
                                <img src={image2} />
                            </div>
                        </div>
                        <div ref={boxImage4} style={{width:'100%',display:'flex', marginTop:'30px',opacity:(position-3248)/200}}>
                            <img src={image4}/>
                        </div>
                        <div ref={boxImage5} style={{width:'100%',display:'flex', marginTop:'30px',opacity:(position-3793)/200}}>
                            <img src={image5}/>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* techpage_2 end */}
            {/* techpage_3 start */}
            <div style={{width:'auto',height:'auto'}}>
                <div style={{width:'100%',position:'relative', display:'flex', justifyContent:'center',alignItems:'flex-start', backgroundColor:'black'}}>
                    <div style={{width:'100%',height:'20%',backgroundColor:'',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:'10%',marginBottom:'10%'}}>
                        <div style={{width:'80%',height:'70%',backgroundColor:'',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <span style={{color:'white',fontWeight:'bolder',fontSize:'55px', opacity:(position-2517)/200, transition:'all 1s ease'}}>GLocal (Global + Local) Instrument = GLINS</span>
                        </div>
                        <div style={{width:'100%',height:'20%',backgroundColor:'', marginTop:'50px', display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                            <span ref={batteryText1}  style={{color:'white', fontSize:'30px', fontWeight:'bolder', opacity:(position-2517)/200, transition:'all 1s ease'}}>우리는 로컬 전략을 구축하는 동시에</span>
                            <span ref={batteryText2} style={{color:'white', fontSize:'30px', fontWeight:'bolder', marginTop:'5px', opacity:(position-2517)/200, transition:'all 1s ease'}}>세계로의 확장성을 추구하며 글로벌 경쟁력을 갖춘 GLINS입니다.</span>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* techpage_3 end */}
            {/* techpage_4 start */}
            <div id="#battery" style={{width:'100%',height:'100%',backgroundColor:'black',position:'relative', display:'flex', justifyContent:'center',alignItems:'center'}}>
                <div style={{width:'996px', height:'706px', position:'relative', display:'flex', marginBottom:'200px'}}>
                    <img  alt="" src={tech_tractor} width={'100%'}/>
                </div>
            </div>
            {/* techpage_4 end */}
            
            {/* techpage_5 start */}
            <div style={{width:'auto',height:'auto'}}>
                <div style={{width:'100%',position:'relative', display:'flex', justifyContent:'center',alignItems:'flex-start', backgroundColor:'black'}}>
                    <div style={{width:'1050px',display:'flex', justifyContent:'center',alignItems:'center',flexWrap:'wrap',backgroundColor:''}}>
                        <div ref={batteryBox1}  style={{width:'510px',height:'510px',backgroundColor:'#1e1e1e',opacity:(position-3844)/200, margin:'10px', marginBottom:'20px', transition:'all 1s ease'}}>
                            <div style={{width:'100%',height:'35%', backgroundColor:'',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                <div style={{width:'100%',height:'90%',backgroundColor:'',display:'flex',flexDirection:'column' ,justifyContent:'center',alignItems:'flex-start', color:'white', padding:20}}>
                                    <span style={{fontSize:'30px',fontWeight:'bolder',margin:0, paddingLeft:10}}>T-ECU MAKER-CONTROL</span>
                                    <div style={{width:'100%',height:'25%',backgroundColor:'',display:'flex', flexDirection:'row'}}>
                                        <div style={{width:'11%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'13px'}}>POC</span>
                                        </div> 
                                        <div style={{width:'15%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'13px'}}>정밀제어</span>
                                        </div>  
                                        <div style={{width:'12%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'13px'}}>원격</span>
                                        </div>  
                                    </div>
                                    
                                </div>
                            </div>
                            <div style={{width:'100%',height:'65%', backgroundColor:''}}>
                                <p style={{color:'gray',fontWeight:'bolder', fontSize:'20px',padding:20}}>
                                T-ECU를 통해 농업기계의 핵심인 기계식, 전자식, 유압식의 PTO 제어하고, 농업기계 제조사의 선택에 따라 하나의 제어기로 다양한 PTO 설치가 가능한것은 물론, 무선 이동 통신모듈의 탑재로 원격으로 PTO제어가 가능합니다.
                                </p>
                            </div>
                        </div>
                        <div ref={batteryBox2}  style={{width:'510px',height:'510px',backgroundColor:'#1e1e1e',opacity:(position-3844)/200, marginBottom:'10px', transition:'all 1s ease'}}>
                            <div  style={{width:'100%',height:'35%', backgroundColor:'',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                <div style={{width:'100%',height:'100%',backgroundColor:'',display:'flex',flexDirection:'column' ,justifyContent:'center',alignItems:'flex-start', color:'white',padding:20}}>
                                    <span style={{fontSize:'30px',fontWeight:'bolder',margin:0, paddingLeft:10}}>T-ECU MAKER-SELF-DIAGNOSIS</span>
                                    <div style={{width:'100%',height:'25%',backgroundColor:'',display:'flex', flexDirection:'row'}}>
                                        <div style={{width:'17%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'13px'}}>고장발생</span>
                                        </div> 
                                        <div style={{width:'17%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'13px'}}>자가진단</span>
                                        </div>  
                                        <div style={{width:'17%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'13px'}}>자동차단</span>
                                        </div>  
                                    </div>
                                </div>
                            </div>  
                            <div style={{width:'100%',height:'65%', backgroundColor:''}}>
                                <p style={{color:'gray',fontWeight:'bolder', fontSize:'20px',padding:20}}>
                                고장의 원인을 알수없다면 어떨까요? <br/>
                                T-ECU의 자가진단 기술을 통해 원인을 분석해주고 계기판과 어플리케이션으로 공유하여 보다 편리하게 고장발생을 감지합니다. 
                                그리고 농작업에 대한 LOSS를 줄이기 위해 관련데이터들을 주변 A/S센터로 전송해 수리에 걸리는 시간을 줄일수있습니다.
                                </p>
                            </div>      
                        </div>
                        <div ref={batteryBox3}  style={{width:'510px',height:'510px',backgroundColor:'#1e1e1e',marginTop:'20px',opacity:(position-4362)/200, margin:'10px', transition:'all 1s ease'}}>
                            <div style={{width:'100%',height:'35%', backgroundColor:'',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                <div style={{width:'100%',height:'100%',backgroundColor:'',display:'flex',flexDirection:'column' ,justifyContent:'center',alignItems:'flex-start', color:'white',padding:20}}>
                                    <span style={{fontSize:'30px',fontWeight:'bolder',margin:0, paddingLeft:10}}>T-ECU USER</span>
                                    <div style={{width:'100%',height:'25%',backgroundColor:'',display:'flex', flexDirection:'row'}}>
                                        <div style={{width:'11%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'13px'}}>OBD2</span>
                                        </div> 
                                        <div style={{width:'20%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'13px'}}>T-ECU진단</span>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            <div style={{width:'100%',height:'65%', backgroundColor:''}}>
                                <p style={{color:'gray',fontWeight:'bolder', fontSize:'20px',padding:20}}>
                                Plug&Play 기능 지원과, 표준(OBD2) 커넥터를 통하여 보다 편리하게 사용이 가능합니다.
                                기존 OBD2를 사용하는 진단기의 경우 제조사들마다 특정 진단 프로토콜이 비공개로 호환되지 않지만 T-ECU의 진단기술 덕분에 특수한 프로토콜까지 진단이 가능합니다. 

                                </p>
                            </div> 
                        </div>
                        <div ref={batteryBox4}  style={{width:'510px',height:'510px',backgroundColor:'#1e1e1e',marginTop:'20px',opacity:(position-4362)/200, marginBottom:'20px', transition:'all 1s ease'}}>
                            <div style={{width:'100%',height:'35%', backgroundColor:'',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                <div style={{width:'100%',height:'100%',backgroundColor:'',display:'flex',flexDirection:'column' ,justifyContent:'center',alignItems:'flex-start', color:'white',padding:20}}>
                                    <span style={{fontSize:'30px',fontWeight:'bolder',margin:0, paddingLeft:10}}>T-ECU TO-DO</span>
                                    <div style={{width:'100%',height:'25%',backgroundColor:'',display:'flex', flexDirection:'row'}}>
                                        <div style={{width:'20%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'13px'}}>원격업데이트</span>
                                        </div> 
                                        <div style={{width:'15%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'13px'}}>OTA기능</span>
                                        </div>  
                                        <div style={{width:'20%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'13px'}}>빅데이터분석</span>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            <div style={{width:'100%',height:'65%', backgroundColor:''}}>
                                <p style={{color:'gray',fontWeight:'bolder', fontSize:'20px',padding:20}}>
                                농작업의 효율을 높일수있도록 구현하였습니다.
                                TO-DO플랫폼을 통해 작업내역을 기록하고 모니터링할수있으며 보다 편리한 관리가 가능합니다.
                                빅데이터 기반의 분석을 통해 농작업의 효율성이 높아지도록 개발했습니다.
                                원격업데이트, 모니터링, 구매 까지 T-ECU를 사용하는 모든 사용자는 TO-DO Service를 제공받을 수 있습니다. 
                                </p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            {/* techpage_5 end */}
            {/* techpage_6 start */}
            <div style={{width:'auto',height:'auto'}}>
                <div style={{width:'100%',position:'relative', display:'flex', justifyContent:'center',alignItems:'flex-start', backgroundColor:'black', color:'white'}}>
                    <div style={{width:'1050px',display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'flex-start',backgroundColor:'',marginTop:'200px', marginBottom:'200px', position:'relative', transition:'all 2s ease'}}>
                        <img src={appDownload} width={'200px'} alt="" style={{backgroundColor:'', opacity:(position-4804)/100 , marginLeft:'20px'}} />
                        <span ref={appText1} className={position>4981 ? styles.appText1Ani : styles.appText1} style={{color:'white', position:'absolute', left:'250px',top:'50px', fontSize:'40px', opacity : (position-4981)/100}}>생산부터 유통까지 원스톱으로</span>
                        <span ref={appText2} className={position>4981 ? styles.appText2Ani: styles.appText2} style={{color:'white', position:'absolute', left:'250px',top:'100px', fontSize:'40px', opacity : (position-4981)/100}}>농장경영에 필요한</span>
                        <span ref={appText3} className={position>4981 ? styles.appText3Ani : styles.appText3} style={{color:'white', position:'absolute', left:'250px',top:'150px', fontSize:'40px', opacity : (position-4981)/100}}>데이터 솔루션이 한곳에</span>
                        <span ref={appText4} className={position>4981 ? styles.appText4Ani : styles.appText4} style={{color:'white', position:'absolute', left:'250px',top:'200px', fontSize:'40px', opacity : (position-4981)/100}}>google / apple</span>
                    </div>
                </div>
            </div>
            {/* techpage_6 end */}
            {/* techpage_9 start */}
            <div style={{width:'auto',height:'auto'}}>
                <div style={{width:'100%',position:'relative', display:'flex', flexDirection:'column' ,justifyContent:'center',alignItems:'center'}}>
                    <img src={techLast} width={'100%'} height={'100%'} alt=""  style={{position:'absolute', zIndex:-1, top:0, left:0, opacity:0.7}}/>
                    <div style={{width:'1250px', height:'100vh', position:'relative',display:'flex',flexDirection:'column', justifyContent:'flex-start',alignItems:'center', backgroundColor:'', marginTop:'150px', color:'white',fontWeight:'bolder'}}>
                        <div style={{width:'100%', height:'20%',backgroundColor:'',display:'flex',textAlign:'center',flexDirection:'column', fontSize:'40px',color:'black'}}>
                            <span style={{fontWeight:'bolder',padding:10, fontSize:'50px'}}>Only, The Better Agricultural Environment</span>
                            <span style={{marginTop:'30px'}}>농부의 내일을 위한 진짜 혁신</span>
                            <span>GLINS가 달려온 사명입니다</span>
                        </div>
                        <div style={{width:'90%', backgroundColor:'',display:'flex',flexDirection:'row', justifyContent:'space-between',alignItems:'flex-start' ,marginTop:'100px', flexWrap:'wrap', marginBottom:'100px'}}>
                            <div style={{width:'15%',height:'210px',color:'white', borderRadius:15, backgroundColor:'#262626',marginRight:'1px',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',opacity:0.9}}>
                                <h2 style={{margin:0}}>이미지1</h2>
                                <h2 style={{margin:0}}>설명1</h2>
                                <h2 style={{margin:0}}>설명2</h2>
                            </div>
                            <div style={{width:'15%',height:'210px',color:'white', borderRadius:15, backgroundColor:'#262626', display:'flex',justifyContent:'center',alignItems:'center',marginRight:'1px',flexDirection:'column',opacity:0.9}}>
                                <h2 style={{margin:0}}>이미지1</h2>
                                <h2 style={{margin:0}}>설명1</h2>
                                <h2 style={{margin:0}}>설명2</h2>
                            </div>
                            <div style={{width:'15%',height:'210px',color:'white', borderRadius:15, backgroundColor:'#262626', display:'flex',justifyContent:'center',alignItems:'center',marginRight:'1px',flexDirection:'column',opacity:0.9}}>
                                <h2 style={{margin:0}}>이미지1</h2>
                                <h2 style={{margin:0}}>설명1</h2>
                                <h2 style={{margin:0}}>설명2</h2>
                            </div>
                            <div style={{width:'15%',height:'210px',color:'white', borderRadius:15, backgroundColor:'#262626', display:'flex',justifyContent:'center',alignItems:'center',marginRight:'1px',flexDirection:'column',opacity:0.9}}>
                                <h2 style={{margin:0}}>이미지1</h2>
                                <h2 style={{margin:0}}>설명1</h2>
                                <h2 style={{margin:0}}>설명2</h2>
                            </div>
                            <div style={{width:'15%',height:'210px',color:'white', borderRadius:15, backgroundColor:'#262626', display:'flex',justifyContent:'center',alignItems:'center',marginRight:'1px',flexDirection:'column',opacity:0.9}}>
                                <h2 style={{margin:0}}>이미지1</h2>
                                <h2 style={{margin:0}}>설명1</h2>
                                <h2 style={{margin:0}}>설명2</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* techpage_9 end */}
        </>
    )
}

export default Tech_isLapTopL;