import { useEffect, useState, useRef} from 'react';
import styles from '../css/tech.module.css';
import techStyle from '../css/tech.module.css';
import test2 from '../assets/test2.jpeg';
import tracter from '../assets/tracter.jpeg';
import mobileBattery from '../assets/mobileBattery.jpeg'
import Footer from '../layout/footer';
import Header from '../layout/header';
import techLast from '../assets/techLast.jpeg';
import appDownload from '../assets/appDownload.png';
import appImage2 from '../assets/appImage2.png';
import tech_tractor from '../assets/tech_tractor.jpg';
import { useMediaQuery } from 'react-responsive';
import { Element, Events, animateScroll as scroll} from 'react-scroll';

import Tech_isFold from './tech/tech_isFold';
import Tech_isLapTop from './tech/tech_isLapTop';
import Tech_isLapTopL from './tech/tech_isLapTopL';
import Tech_isMobileL from './tech/tech_isMobileL';
import Tech_default from './tech/tech_default';
import Tech_isTablet from './tech/tech_isTablet';

export default function Tech(props){
    /* responsive area ==== start ==== */
    const isTablet = useMediaQuery({query:'(max-width:768px)'});
    const isMobileS =  useMediaQuery({query:'(max-width:320px)'});
    const isMobileM = useMediaQuery({query:'(max-width:375px)'});
    const isMobileL = useMediaQuery({query:'(max-width:425px)'});
    const isLapTop = useMediaQuery({query:'(max-width:1024px)'});
    const isLapTopL = useMediaQuery({query:'(max-width:1440px)'});
    const is4K = useMediaQuery({query:'(max-width:2560px)'});
    const isFold = useMediaQuery({query:'(max-width:280px)'});
    /* responsive area ==== end ==== */


    const [position , setPosition ] = useState(0);
    const [techText ,setTechText] = useState('');
    const [isVisible, setIsVisible] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [headerVisible , setHeaderVisible] = useState(true);

    let prevScroll = 0;

    const battery1 = useRef();
    const battery2 = useRef();
    const battery3 = useRef();
    const battery4 = useRef();

    const batteryBox1 = useRef();
    const batteryBox2 = useRef();
    const batteryBox3 = useRef();
    const batteryBox4 = useRef();

    const batteryText1 = useRef();
    const batteryText2 = useRef();

    const techTruckImage = useRef();
    const techTruckText = useRef();
    const techTruckText2 = useRef();

    const appText1 = useRef();
    const appText2 = useRef();
    const appText3 = useRef();
    const appText4 = useRef();

    const title1 = useRef();
    const title2 = useRef();
    const title3 = useRef();
    const title4 = useRef();
    const title5 = useRef();

    // let text = ['T','-','E','C','U'];
    let text = 'T-ECU';
    let currentIndex = 0;

    const temp = useRef('');
    const scroll = useRef();

    useEffect(()=>{
        window.scrollTo(0,0);


        //해당 dom으로 이동
        const sectionId = window.location.hash.slice(1);
        if(sectionId){
            const element = document.getElementById(sectionId.split('#')[1]);
            if(element){
                element.scrollIntoView({behavior:'smooth'});
            }
        }

        temp.current = "";  //초기화
        const textInterval = setInterval(() => {
            if(currentIndex === text.length){
                clearInterval(textInterval);
            }else{
                temp.current  = temp.current + text[currentIndex];
                currentIndex ++;
                setTechText(temp.current);
            }
        }, 200); // 각 글자가 나타나는 시간 간격 (ms)

        const delay = setTimeout(()=>{
            if(isTablet){
                techTruckImage.current.classList.add(styles.techTruckImageShow_mobile);
            }else{
                techTruckImage.current.classList.add(styles.techTruckImageShow);
            }
        },200)

        // showTechText();
       
        Events.scrollEvent.register('begin', function(to, element){
            //스크롤 시작할때 할 일
        });

        Events.scrollEvent.register('end', function(to, element){
            //스크롤 끝날때 할 일
        });
        
        window.addEventListener('scroll', onScroll);

        // 컴포넌트가 언마운트될 때 인터벌을 정리합니다.
        return () => {
            window.removeEventListener('scroll', onScroll);
            clearInterval(textInterval);
            clearInterval(delay);
        };
    },[]);

    const onScroll = ()=>{
        const techScroll = window.scrollY;
        //scroll.current =  techScroll;
        setPosition(window.scrollY);

        if(techScroll < prevScroll){
            setHeaderVisible(true);
        }else{
            setHeaderVisible(false);
        }
        prevScroll = techScroll;

        if(isMobileL){
            if(techScroll > 7191){

            }
        }

        if(techScroll >=500 && techScroll<=1000){

            if(isLapTopL){
                if(isTablet){
                    techTruckText2.current.style.opacity = techScroll/800;
                    techTruckText2.current.style.transition = `all 2s ease`;

                    techTruckImage.current.style.transform = `translate3d(0px,-100px,0px)`;
                    techTruckImage.current.style.transition = `all 3s ease`;

                    techTruckText.current.style.transform = `translate3d(0px,150px,0px)`;
                }else{
                    if(isLapTop){
                        techTruckImage.current.style.transform = `translate3d(250px,-100px,200px) scale(0.7)`;
                        techTruckImage.current.style.transition = `all 3s ease`;
                        techTruckImage.current.style.opacity = 1;

                        techTruckText.current.style.transform = `translate3d(-150px,-50px,200px)`;
                        techTruckText.current.style.transition = `all 2s ease`;
                        techTruckText.current.style.fontSize = '150px';

                        techTruckText2.current.style.opacity = techScroll/800;
                        techTruckText2.current.style.transition = `all 2s ease`;
                    }else{
                        techTruckImage.current.style.transform = `translate3d(300px,-100px,200px) scale(0.7)`;
                        techTruckImage.current.style.transition = `all 3s ease`;
                        techTruckImage.current.style.opacity = 1;

                        techTruckText.current.style.transform = `translate3d(-350px,-50px,100px)`;
                        techTruckText.current.style.transition = `all 2s ease`;
                        techTruckText.current.style.fontSize = '150px';

                        techTruckText2.current.style.opacity = techScroll/800;
                        techTruckText2.current.style.transition = `all 2s ease`;
                    }
                    
                }
            }else{
                techTruckImage.current.style.transform = `translate3d(300px,-100px,200px) scale(0.7)`;
                techTruckImage.current.style.transition = `all 3s ease`;
                techTruckImage.current.style.opacity = 1;

                techTruckText.current.style.transform = `translate3d(-350px,-50px,100px)`;
                techTruckText.current.style.transition = `all 2s ease`;
                techTruckText.current.style.fontSize = '150px';

                techTruckText2.current.style.opacity = techScroll/800;
                techTruckText2.current.style.transition = `all 2s ease`;
            }
            

        }else if(techScroll<=500){

            if(isTablet){
                techTruckImage.current.style.transform = `translate3d(0px,0px,0px) scale(1,1)`;

                techTruckText2.current.style.opacity = 0;
                techTruckText2.current.style.transition = `all 2s ease`;

                techTruckText.current.style.transform = `translate3d(0px,0px,0px)`;
                techTruckText.current.style.transition = `all 2s ease`;
            }else{
                techTruckImage.current.style.transform = `translate3d(0px,0px,0px) scale(1,1)`;
                techTruckImage.current.style.opacity = 0.7;

                techTruckText.current.style.transform = `translate3d(0px,0px,0px)`;
                techTruckText.current.style.transition = `all 2s ease`;
                techTruckText.current.style.fontSize = '250px';

                techTruckText2.current.style.opacity = 0;
                techTruckText2.current.style.transition = `all 2s ease`;
            }
            
        }
    }


    return(
        <div style={{width:'auto',height:'auto'}}>
            {headerVisible && <Header/>}
            {isFold ? 
                <Tech_isFold position={position} techTruckImage={techTruckImage} techTruckText={techTruckText} techTruckText2={techTruckText2} batteryText1={batteryText1} batteryText2={batteryText2} batteryBox1={batteryBox1} batteryBox2={batteryBox2} batteryBox3={batteryBox3} batteryBox4={batteryBox4} appText1={appText1} appText2={appText2} appText3={appText3} appText4={appText4}  techText={techText}/>
            :
                <>
                {isMobileL ? 
                    <Tech_isMobileL position={position} techTruckImage={techTruckImage} techTruckText={techTruckText} techTruckText2={techTruckText2} batteryText1={batteryText1} batteryText2={batteryText2} batteryBox1={batteryBox1} batteryBox2={batteryBox2} batteryBox3={batteryBox3} batteryBox4={batteryBox4} appText1={appText1} appText2={appText2} appText3={appText3} appText4={appText4} techText={techText}/>
                :   
                    <>
                    {isTablet ?
                        <Tech_isTablet position={position} techTruckImage={techTruckImage} techTruckText={techTruckText} techTruckText2={techTruckText2} batteryText1={batteryText1} batteryText2={batteryText2} batteryBox1={batteryBox1} batteryBox2={batteryBox2} batteryBox3={batteryBox3} batteryBox4={batteryBox4} appText1={appText1} appText2={appText2} appText3={appText3} appText4={appText4} techText={techText}/>
                    :   
                        <>
                        {isLapTop ?
                            <Tech_isLapTop position={position} techTruckImage={techTruckImage} techTruckText={techTruckText} techTruckText2={techTruckText2} batteryText1={batteryText1} batteryText2={batteryText2} batteryBox1={batteryBox1} batteryBox2={batteryBox2} batteryBox3={batteryBox3} batteryBox4={batteryBox4} appText1={appText1} appText2={appText2} appText3={appText3} appText4={appText4} techText={techText}/>
                        :
                            <>
                            {isLapTopL ?
                                <Tech_isLapTopL position={position} techTruckImage={techTruckImage} techTruckText={techTruckText} techTruckText2={techTruckText2} batteryText1={batteryText1} batteryText2={batteryText2} batteryBox1={batteryBox1} batteryBox2={batteryBox2} batteryBox3={batteryBox3} batteryBox4={batteryBox4} appText1={appText1} appText2={appText2} appText3={appText3} appText4={appText4} techText={techText}/>
                            :
                                <Tech_default position={position} techTruckImage={techTruckImage} techTruckText={techTruckText} techTruckText2={techTruckText2} batteryText1={batteryText1} batteryText2={batteryText2} batteryBox1={batteryBox1} batteryBox2={batteryBox2} batteryBox3={batteryBox3} batteryBox4={batteryBox4} appText1={appText1} appText2={appText2} appText3={appText3} appText4={appText4} techText={techText}/>
                            }
                            </>
                        }
                        </>
                    }
                    </>
                }
                </>
            }
            <Footer/>
        </div>
    )
}