import styles from '../../css/tech.module.css';
import techStyle from '../../css/tech.module.css';
import test2 from '../../assets/test2.jpeg';
import tracter from '../../assets/tracter.jpeg';
import mobileBattery from '../../assets/mobileBattery.jpeg'
import techLast from '../../assets/techLast.jpeg';
import appDownload from '../../assets/appDownload.png';
import appImage2 from '../../assets/appImage2.png';
import tech_tractor from '../../assets/tech_tractor.jpg';
import { useEffect, useRef } from 'react';


const Tech_isFold = ({position, techTruckImage, techTruckText,techTruckText2,batteryText1,batteryText2, batteryBox1,batteryBox2, batteryBox3, batteryBox4,appText1 , appText2, appText3, appText4, techText})=>{


    return(
        <>
            {/* first page - start */}
            <div className={styles.fullcContainer} >
                <div className={styles.sticky_container} >
                    <div className={styles.sticky}>
                        <div className={styles.slide_container}>
                            <div className={styles.slide}>
                                <div style={{width:'100%', height:'100%', backgroundColor:'black',position:'relative', paddingTop:'80px'}}>
                                    <div style={{width:'100%',height:'100%',backgroundColor:'black',position:'absolute',display:'flex', justifyContent:'center',alignItems:'center', overflow:'hidden',margin:'auto'}}>
                                        <div style={{width:'1500px',height:'1000px',backgroundColor:'black', position:'relative', display:'felx',justifyContent:'center',alignItems:'center'}}>
                                            <div style={{width:'100%',height:'100%', backgroundColor:'black', display:'flex', justifyContent:'center',alignItems:'center'}}>
                                                <img alt=""  ref={techTruckImage} className={styles.techTruckImage_mobile} src={tracter} width={'100%'} style={{marginTop:'150px'}}/>
                                            </div>
                                            <div ref={techTruckText} style={{position:'absolute', width:'100%', height:'25%', top:'15%',left:'0%',backgroundColor:'',display:'flex', justifyContent:'center',alignItems:'center',fontSize:'70px',fontWeight:'bolder',color:'white',zIndex:0}}>
                                                <div style={{width:'100%',height:'100%',backgroundColor:'',display:'flex', flexDirection:"row",justifyContent:'center',alignItems:'center'}}>
                                                    <span style={{transition:'all 1s ease' }}>{techText}</span>
                                                </div>
                                            </div>
                                            <div  ref={techTruckText2}style={{position:'absolute',width:'100%', height:'20%',backgroundColor:'',top:'450px', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'column', opacity:0}}>
                                                <div style={{width:'90%',height:'50%',backgroundColor:'', display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                                                    <div style={{width:'100%',display:'flex',flexDirection:'column',justifyContent:'center', alignItems:'center'}}>
                                                        <span style={{color:'white', fontWeight:'bolder', fontSize:'16px',padding:10}}>스마트 농업 지능형 농업 기계 솔루션</span>
                                                        <span style={{color:'white', fontWeight:'bolder', fontSize:'15px'}}>디바이스를 통한 간편한 첨단 기술 보급은</span>
                                                        <span style={{color:'white', fontWeight:'bolder', fontSize:'15px'}}>농업 기계 제조와 관리를 더욱 편리하게</span>
                                                        <span style={{color:'white', fontWeight:'bolder', fontSize:'15px'}}>만듭니다.</span>
                                                    </div>
                                                </div>
                                            </div>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* techpage_1 end */}
            {/* techpage_2 start - 이후 추가 될 영역 */}
            {/* <div style={{width:'auto',height:'auto'}}>
                <div style={{width:'100%',position:'relative', display:'flex', justifyContent:'center',alignItems:'center', backgroundColor:'white'}}>
                    <div style={{width:'auto',height:'auto',display:'flex',backgroundColor:'' ,justifyContent:'center',alignItems:'flex-start',flexWrap:'wrap', marginTop:'200px', marginBottom:'60px', padding:'20px'}}>
                        <img src={image1} width={'100%'} />
                        <img src={image3} width={'100%'} style={{marginTop:'20px'}}/>
                        <img src={image4} width={'100%'} style={{marginTop:'20px'}}/>
                        <img src={image5} width={'100%'} style={{marginTop:'20px'}}/>
                    </div>
                </div>
            </div> */}
            {/* techpage_2 end */}
            {/* techpage_3 start */}
            <div style={{width:'auto',height:'auto'}}>
                <div style={{width:'100%',position:'relative', display:'flex', justifyContent:'center',alignItems:'flex-start', backgroundColor:'black'}}>
                    <div style={{width:'100%',height:'20%',backgroundColor:'',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:'20%'}}>
                        <div style={{width:'100%',height:'70%',backgroundColor:'',display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'column'}}>
                            <span style={{color:'white',fontWeight:'bolder',fontSize:'16px'}}>GLocal (Global + Local) Instrument</span>
                            <span style={{color:'white',fontWeight:'bolder',fontSize:'16px', marginTop:'10px'}}> = GLINS</span>
                        </div>
                        <div style={{width:'100%',height:'20%',backgroundColor:'', marginTop:'50px', display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center', marginBottom:'20%'}}>
                            <span ref={batteryText1}  style={{color:'white', fontSize:'16px', fontWeight:'bolder'}}>우리는 로컬 전략을 구축하는 동시에</span>
                            <span ref={batteryText2} style={{color:'white', fontSize:'16px', fontWeight:'bolder', marginTop:'5px'}}>세계로의 확장성을 추구하며 </span>
                            <span ref={batteryText2} style={{color:'white', fontSize:'16px', fontWeight:'bolder', marginTop:'5px'}}>경쟁력을 갖춘 GLINS입니다. </span>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* techpage_3 end */}
            {/* techpage_4 start */}
            <div id="#battery" style={{width:'100%',height:'100%',backgroundColor:'black',position:'relative', display:'flex', justifyContent:'center',alignItems:'center'}}>
                <div style={{width:'auto', height:'auto',margin:'auto' ,position:'relative', display:'flex'}}>
                    <img alt="" src={tech_tractor} width={'100%'}/>
                </div>
            </div>
            {/* techpage_4 end */}
            
            {/* techpage_5 start */}
            <div style={{width:'auto',height:'auto'}}>
                <div style={{width:'100%',position:'relative', display:'flex', justifyContent:'center',alignItems:'flex-start', backgroundColor:'black'}}>
                    <div style={{width:'1050px',display:'flex', justifyContent:'center',alignItems:'center',flexWrap:'wrap',backgroundColor:'',marginTop:'100px'}}>
                        <div ref={batteryBox1}  style={{width:'90%',height:'450px',backgroundColor:'#1e1e1e'}}>
                            <div style={{width:'100%',height:'35%', backgroundColor:'',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                <div style={{width:'100%',height:'90%',backgroundColor:'',display:'flex',flexDirection:'column' ,justifyContent:'center',alignItems:'flex-start', color:'white', padding:20}}>
                                    <span style={{fontSize:'23px',fontWeight:'bolder',margin:0, paddingLeft:10}}>T-ECU MAKER-</span>
                                    <span style={{fontSize:'23px',fontWeight:'bolder',margin:0, paddingLeft:10}}>CONTROL</span>
                                    <div style={{width:'100%',height:'25%',backgroundColor:'',display:'flex', flexDirection:'row'}}>
                                        <div style={{width:'15%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'11px'}}>POC</span>
                                        </div> 
                                        <div style={{width:'25%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'11px'}}>정밀제어</span>
                                        </div>  
                                        <div style={{width:'15%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'11px'}}>원격</span>
                                        </div>  
                                    </div>
                                    
                                </div>
                            </div>
                            <div style={{width:'100%',height:'65%', backgroundColor:''}}>
                                <p style={{color:'gray',fontWeight:'bolder', fontSize:'15px',padding:20}}>
                                T-ECU를 통해 농업기계의 핵심인 기계식, 전자식, 유압식의 PTO 제어하고, 농업기계 제조사의 선택에 따라 하나의 제어기로 다양한 PTO 설치가 가능한것은 물론, 무선 이동 통신모듈의 탑재로 원격으로 PTO제어가 가능합니다.
                                </p>
                            </div>
                        </div>
                        <div ref={batteryBox2}  style={{width:'90%',height:'450px',marginTop:'20px',backgroundColor:'#1e1e1e'}}>
                            <div  style={{width:'100%',height:'35%', backgroundColor:'',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                <div style={{width:'100%',height:'100%',backgroundColor:'',display:'flex',flexDirection:'column' ,justifyContent:'center',alignItems:'flex-start', color:'white',padding:20}}>
                                    <span style={{fontSize:'23px',fontWeight:'bolder',margin:0, paddingLeft:10}}>T-ECU MAKER-</span>
                                    <span style={{fontSize:'23px',fontWeight:'bolder',margin:0, paddingLeft:10}}>SELF-DIAGNOSIS</span>
                                    <div style={{width:'100%',height:'25%',backgroundColor:'',display:'flex', flexDirection:'row'}}>
                                        <div style={{width:'25%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'11px'}}>고장발생</span>
                                        </div> 
                                        <div style={{width:'25%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'11px'}}>자가진단</span>
                                        </div>  
                                        <div style={{width:'25%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'11px'}}>자동차단</span>
                                        </div>  
                                    </div>
                                </div>
                            </div>  
                            <div style={{width:'100%',height:'65%', backgroundColor:''}}>
                                <p style={{color:'gray',fontWeight:'bolder', fontSize:'15px',padding:20}}>
                                고장의 원인을 알수없다면 어떨까요? <br/>
                                T-ECU의 자가진단 기술을 통해 원인을 분석해주고 계기판과 어플리케이션으로 공유하여 보다 편리하게 고장발생을 감지합니다. 
                                그리고 농작업에 대한 LOSS를 줄이기 위해 관련데이터들을 주변 A/S센터로 전송해 수리에 걸리는 시간을 줄일수있습니다.
                                </p>
                            </div>     
                        </div>
                        <div ref={batteryBox3}  style={{width:'90%',height:'450px',backgroundColor:'#1e1e1e',marginTop:'20px'}}>
                            <div style={{width:'100%',height:'35%', backgroundColor:'',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                <div style={{width:'100%',height:'100%',backgroundColor:'',display:'flex',flexDirection:'column' ,justifyContent:'center',alignItems:'flex-start', color:'white',padding:20}}>
                                    <span style={{fontSize:'23px',fontWeight:'bolder',margin:0, paddingLeft:10}}>T-ECU USER</span>
                                    <div style={{width:'100%',height:'25%',backgroundColor:'',display:'flex', flexDirection:'row'}}>
                                        <div style={{width:'20%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'11px'}}>OBD2</span>
                                        </div> 
                                        <div style={{width:'35%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'11px'}}>T-ECU진단</span>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            <div style={{width:'100%',height:'65%', backgroundColor:''}}>
                                <p style={{color:'gray',fontWeight:'bolder', fontSize:'18px',padding:20}}>
                                Plug&Play 기능 지원과, 표준(OBD2) 커넥터를 통하여 보다 편리하게 사용이 가능합니다.
                                기존 OBD2를 사용하는 진단기의 경우 제조사들마다 특정 진단 프로토콜이 비공개로 호환되지 않지만 T-ECU의 진단기술 덕분에 특수한 프로토콜까지 진단이 가능합니다. 

                                </p>
                            </div>
                        </div>
                        <div ref={batteryBox4}  style={{width:'90%',height:'450px',backgroundColor:'#1e1e1e',marginTop:'20px'}}>
                            <div style={{width:'100%',height:'35%', backgroundColor:'',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                <div style={{width:'100%',height:'100%',backgroundColor:'',display:'flex',flexDirection:'column' ,justifyContent:'center',alignItems:'flex-start', color:'white',padding:20}}>
                                    <span style={{fontSize:'23px',fontWeight:'bolder',margin:0, paddingLeft:10}}>T-ECU TO-DO</span>
                                    <div style={{width:'100%',height:'25%',backgroundColor:'',display:'flex', flexDirection:'row'}}>
                                        <div style={{width:'35%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'11px'}}>원격업데이트</span>
                                        </div> 
                                        <div style={{width:'25%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'11px'}}>OTA기능</span>
                                        </div>  
                                        <div style={{width:'35%',height:'40%', backgroundColor :'white',border:'1px solid white', borderRadius:20, marginTop:'10px', marginLeft:'10px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{color:'black',fontWeight:'bolder', fontSize:'11px'}}>빅데이터분석</span>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            <div style={{width:'100%',height:'65%', backgroundColor:''}}>
                                <p style={{color:'gray',fontWeight:'bolder', fontSize:'16px',padding:20}}>
                                농작업의 효율을 높일수있도록 구현하였습니다.
                                TO-DO플랫폼을 통해 작업내역을 기록하고 모니터링할수있으며 보다 편리한 관리가 가능합니다.
                                빅데이터 기반의 분석을 통해 농작업의 효율성이 높아지도록 개발했습니다.
                                원격업데이트, 모니터링, 구매 까지 T-ECU를 사용하는 모든 사용자는 TO-DO Service를 제공받을 수 있습니다. 
                                </p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            {/* techpage_5 end */}
            {/* techpage_6 start */}
            <div style={{width:'auto',height:'auto'}}>
                <div style={{width:'100%',position:'relative', display:'flex', justifyContent:'center',alignItems:'flex-start', backgroundColor:'black', color:'white'}}>
                    <div style={{width:'1050px',display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'flex-start',backgroundColor:'',marginTop:'200px', marginBottom:'200px', position:'relative', transition:'all 2s ease'}}>
                        <img src={appImage2} alt="" width={'90%'} style={{backgroundColor:'', opacity:(position-4804)/100}} />
                        <span style={{color:'white', position:'absolute', left:'80px',top:'100px', fontSize:'30px', opacity : (position-4981)/100, transition:'all 1s ease', transitionDelay:'0.5s', transform:'rotate(2deg)'}}><span style={{fontSize:'30px',fontWeight:'bold'}}>생산</span>부터 </span>
                        <span style={{color:'white', position:'absolute', left:'80px',top:'140px', fontSize:'30px', opacity : (position-4981)/100, transition:'all 1s ease', transitionDelay:'0.5s', transform:'rotate(2deg)'}}><span style={{fontSize:'30px',fontWeight:'bold'}}>유통</span>까지 <br/>원스톱으로</span>
                        <span style={{color:'white', position:'absolute', left:'100px',top:'330px', fontSize:'24px', opacity : (position-4981)/100, transition:'all 1s ease', transitionDelay:'1.5s', transform:'rotate(-1deg)'}}>SMART</span>
                        <span style={{color:'white', position:'absolute', left:'90px',top:'360px', fontSize:'24px', opacity : (position-4981)/100, transition:'all 1s ease', transitionDelay:'1.5s', transform:'rotate(-1deg)'}}>FARMING</span>
                        <span style={{color:'white', position:'absolute', left:'75px',top:'390px', fontSize:'24px', opacity : (position-4981)/100, transition:'all 1s ease', transitionDelay:'1.5s', transform:'rotate(-1deg)'}}>SOLUTIONS</span>
                    </div>
                </div>
            </div>
            {/* techpage_6 end */}
            {/* techpage_9 start */}
            <div style={{width:'auto',height:'auto'}}>
                <div style={{width:'100%',position:'relative', display:'flex', flexDirection:'column' ,justifyContent:'center',alignItems:'center'}}>
                    <img src={techLast} width={'100%'} height={'100%'} alt=""  style={{position:'absolute', zIndex:-1, top:0, left:0, opacity:0.7}}/>
                    <div style={{width:'100%', height:'100%',display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center', backgroundColor:'', marginTop:'40px', color:'white',fontWeight:'bolder',marginBottom:'40px'}}>
                        <div style={{width:'100%', height:'20%',backgroundColor:'',display:'flex', justifyContent:'center',alignItems:'center',flexDirection:'column', fontSize:'23px'}}>
                            <span style={{fontWeight:'bolder',padding:10, fontSize:'25px', textAlign:'center', color : 'black'}}>Only, The Better Agricultural Environment</span>
                            <span style={{marginTop:'30px', color : 'black'}}>농부의 내일을 위한 진짜 혁신</span>
                            <span style={{color : 'black'}}>GLINS가 달려온 사명입니다</span>
                        </div>
                        <div style={{width:'100%', backgroundColor:'',display:'flex',flexDirection:'row', justifyContent:'space-between',alignItems:'center' ,marginTop:'50px', flexWrap:'wrap'}}>
                            <div style={{width:'40%',height:'100px',color:'white', borderRadius:15, backgroundColor:'#00000099',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column', fontSize:'10px', marginLeft:'20px'}}>
                                <h2 style={{margin:0}}>이미지1</h2>
                                <h2 style={{margin:0}}>설명1</h2>
                                <h2 style={{margin:0}}>설명2</h2>
                            </div>
                            <div style={{width:'40%',height:'100px',color:'white', borderRadius:15, backgroundColor:'#00000099', display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column', fontSize:'10px', marginRight:'30px'}}>
                                <h2 style={{margin:0}}>이미지1</h2>
                                <h2 style={{margin:0}}>설명1</h2>
                                <h2 style={{margin:0}}>설명2</h2>
                            </div>
                        </div>
                        <div style={{width:'100%', backgroundColor:'',display:'flex',flexDirection:'row', justifyContent:'space-between',alignItems:'center' ,marginTop:'20px', flexWrap:'wrap'}}>
                            <div style={{width:'40%',height:'100px',color:'white', borderRadius:15, backgroundColor:'#00000099',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column', fontSize:'10px', marginLeft:'20px'}}>
                                <h2 style={{margin:0}}>이미지1</h2>
                                <h2 style={{margin:0}}>설명1</h2>
                                <h2 style={{margin:0}}>설명2</h2>
                            </div>
                            <div style={{width:'40%',height:'100px',color:'white', borderRadius:15, backgroundColor:'#00000099', display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column', fontSize:'10px',marginRight:'30px'}}>
                                <h2 style={{margin:0}}>이미지1</h2>
                                <h2 style={{margin:0}}>설명1</h2>
                                <h2 style={{margin:0}}>설명2</h2>
                            </div>
                        </div>
                        <div style={{width:'100%', backgroundColor:'',display:'flex',flexDirection:'row', justifyContent:'space-between',alignItems:'center' ,marginTop:'20px', flexWrap:'wrap'}}>
                            <div style={{width:'40%',height:'100px',color:'white', borderRadius:15, backgroundColor:'#00000099',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column', fontSize:'10px' , marginLeft:'20px'}}>
                                <h2 style={{margin:0}}>이미지1</h2>
                                <h2 style={{margin:0}}>설명1</h2>
                                <h2 style={{margin:0}}>설명2</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* techpage_9 end */}
        </>
    )
}

export default Tech_isFold;