import main_1 from '../../assets/main_1.jpg';
import main_2 from '../../assets/main_2.jpg';
import main_3 from '../../assets/main_3.jpg';
import main_4 from '../../assets/main_4.jpg';
import mianVideo from '../../assets/main.mp4';
import styles from '../../css/main.module.css';

const Main_isMobileL = ({position, mainText1, mainText2, mainText3, mainText4, scmainText1,scmainText2, tcmainText1,tcmainText2,scmainImage})=>{
    return(
        <>
            {/* mainpage_1 start */}
            <div className={styles.sticky_container} style={{}}>
            <div className={styles.sticky}>
                <div className={styles.slide_container}>
                    <div className={styles.slide}>
                        <div className={styles.videoContainer}>
                            <video src={mianVideo} loop autoPlay={true} muted/>
                        </div>
                    </div>
                </div>
            </div>
            </div>


            {/* mainpage_1 end */}
            {/* mainpage_2 start  */}
            <div className={styles.sticky_container}>
            <div className={styles.sticky}>
                <div className={styles.slide_container}>
                    <div  className={styles.slide} style={{backgroundImage:`url(${main_1})`,backgroundSize:'cover' ,backgroundRepeat:'no-repeat', opacity : (position - 2500)/10, transition:'all 1s ease'}}>
                        <div style={{position:'absolute',width:'100%',height:'40%',backgroundColor:'',top:'15%'}}>
                            <div style={{width:'100%', height:'100%' ,backgroundColor:'',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',fontSize:'30px',color:'white',fontWeight:'bolder'}}>
                                <span ref={mainText1} className={position> 3000 ? styles.mainText1Ani : styles.mainText1} style={{opacity:(position-3500)/200, fontSize:'30px'}}>지엘아이엔에스는</span> 
                                <span ref={mainText2} className={position> 3500 ? styles.mainText1Ani : styles.mainText1} style={{opacity:(position-3500)/200, fontSize:'30px'}}>식량위기에 대한 해결책과</span>
                                <span ref={mainText3} className={position> 4000 ? styles.mainText1Ani : styles.mainText1} style={{opacity:(position-4500)/200, fontSize:'30px'}}>지속가능한 농업환경을</span>
                                <span ref={mainText4} className={position> 4500 ? styles.mainText1Ani : styles.mainText1} style={{opacity:(position-4500)/200, fontSize:'30px'}}>혁신합니다.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            {/* mainpage_2 end */}
            {/* mainpage_3 start */}
            <div className={styles.sticky_container}>
                <div className={styles.sticky}>
                    <div className={styles.slide_container}>
                        <div className={styles.slide}>
                            <div style={{width:'100%', height:'100%',position:'relative'}}>
                               <div style={{position:'absolute',width:'100%',height:'100%', zIndex:1, overflow:'hidden', display:'flex', justifyContent:'center', alignItems:'center', opacity:0.8}}>
                                <div className={styles.scmianImageContainer}>
                                        <img  className={position > 5600 ? styles.scmainImageAni : styles.scmainImage} src={main_2} height={'100%'} width={'100%'} />
                                    </div>
                               </div>
                               <div ref={scmainText1} className={position>6000 ? styles.scmainText1Ani : styles.scmainText1} style={{opacity : (position - 6000)/10}}>
                                    <span style={{fontSize:'25px'}}>농업에 대한</span>
                                    <span style={{fontSize:'25px'}}>자연스러운 변화, 그리고 문제</span>
                                </div> 
                                <div  ref={scmainText2} className={styles.scmainText2Div}>
                                    <div className={ position>7000 ? styles.scmainText2SubAni : styles.scmainText2Sub} style={{opacity:(position-7000)/200, fontSize:'15px'}}> 
                                        <span style={{padding:5}}>기존의 농업방식은 화석연료를 기반으로 농업기계를 활용하며</span>
                                        <span style={{padding:5}}>이는 탄소배출에 의해 환경오염과 사회적 문제를 야기합니다.</span>
                                        <span style={{padding:5}}>또한, 기후변화로 인해 불안정해진 기상조건과 </span>
                                        <span style={{padding:5}}>전 세계적인 고령화 인구문제와 농업인구의 감소는</span>
                                        <span style={{padding:5}}>농작물 생산의 불안정성을 증가시킵니다.</span>
                                        <span style={{padding:5, marginTop:'20px'}}>이러한 문제들을 해결하기위한 방법은</span>
                                        <span style={{padding:5}}>지능화된 스마트농업기계와 자율무인농업시스템입니다.</span>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* mainpage_3 end */}
            {/* mainpage_4 start */}
            <div className={styles.sticky_container}>
            <div className={styles.sticky}>
                <div className={styles.slide_container}>
                    <div className={styles.slide}>
                        <div style={{ width:'100%', height:'100%',backgroundImage:`url(${main_3})`,backgroundRepeat:'no-repeat', backgroundSize:'cover'}}>
                        {/*  <div style={{ width:'100%', height:'100%'}}> */}
                            {/* <img src={main_3} width={'100%'} height={'80%'}/> */}
                            <div style={{position: 'absolute',width:'100%',height:'35%',backgroundColor:'' ,display:'flex', top :'55%', flexDirection:'column', alignItems:'flex-start', overflow:'hidden' }} >
                                <div ref={tcmainText1} className={position>9400 ? styles.tcmainText1Ani : styles.tcmainText1} style={{opacity:(position-9300)/100, marginLeft:'10%'}}>
                                    <h2 style={{fontSize:'30px' ,margin :0, padding:5, color: 'white'}}>오직</h2>
                                    <h2 style={{fontSize:'30px' ,margin :0, padding:5, color: 'white'}}>'더 나은 농업환경 개선'</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            {/* mainpage_4 end */}
            {/* mainpage_5 start */}
            <div className={styles.sticky_container}>
            <div className={styles.sticky}>
                <div className={styles.slide_container}>
                    {/* <img src={main_4} width={'100%'} height={'100%'}/> */}
                    <div  style={{position: 'absolute', width:'100%', height:'100%', display:'flex',flexDirection:'column', alignItems:'flex-start',backgroundColor:'', transition:'all 2s ease',backgroundImage:`url(${main_4})`, backgroundSize:'cover',backgroundRepeat:'no-repeat' ,overflow:'hidden'}} >
                        <div ref={tcmainText2} className={position>12300 ? styles.tcmainText2Ani : styles.tcmainText2} style={{width:'100%',height:'100%', opacity:(position-12300)/100}}>
                            <div style={{width:'500px',height:'15%',marginLeft:'10%',marginTop:'450px',backgroundColor:'',display:'flex',flexDirection:'column',justifyContent:'flex-end',alignItems:'flex-start'}}>
                                <h2 style={{fontSize:'30px' ,margin :0, padding:1, color: 'white'}}>우리는</h2>
                                <h2 style={{fontSize:'30px' ,margin :0, padding:1, color: 'white'}}>이 것만 생각합니다.</h2>
                            </div>
                            <div style={{width:'600px',height:'35%',marginLeft:'10%',marginTop:'10px',background:'',display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-start'}}>
                                <h2 style={{margin : 0, fontSize: '20px' , padding :1, fontWeight:'bold', color: 'white'}}>우리의 기술로,</h2>
                                <h2 style={{margin : 0, fontSize: '20px' , padding :1, fontWeight:'bold', color: 'white'}}>농부의 에너지를 오로지 농작물에</h2>
                                <h2 style={{margin : 0, fontSize: '20px' , padding :1, fontWeight:'bold', color: 'white'}}>쏟을 수 있는 일상이 되기를.</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            {/* mainpage_5 end */}
        </>

    )
}

export default Main_isMobileL;
    
    