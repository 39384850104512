import { useEffect, useState, useRef, createRef, cloneElement } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import logo from '../../../assets/glins.png';

export default function AdminHeader(props){

    return(
        <div style={{width:'auto',height:'auto'}}>
            <div style={{width:'auto',height:'auto'}}>
                <div style={{width:'100%',height:'80px',position:'fixed', display:'flex', justifyContent:'center',alignItems:'center', backgroundColor:'white', zIndex:9999}}>
                    <div style={{width:'1500px',height:'100%',display:'flex',justifyContent:'space-between',alignItems:'center',color:'white', backgroundColor:''}}>
                        <div style={{width:'10%',height:'100%',display:'flex',justifyContent:'flex-start',alignItems:'center',backgroundColor:'', marginLeft:'100px'}}>
                            <img src={logo} width={'100%'}/>
                        </div>  
                        <div style={{position:'relative',width:'400px',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'',marginRight:'100px'}}>
                            <div style={{width:'60%',height:'100%',display:'flex',justifyContent:'space-between',alignItems:'center',backgroundColor:'',margin:'20px'}}>
                                <span style={{color:'black',fontWeight:'bolder',fontSize:'18px'}}>뉴스등록</span>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    )
}