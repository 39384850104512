import { useEffect, useState, useRef, createRef, cloneElement } from 'react';
import Footer from '../../layout/footer';
import Header from '../../layout/header';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

export default function NewsDetail(){

    const location = useLocation();
    const [newsCntn , setNewsCntn] = useState('');
    const [newsTitle, setNewsTitle] = useState('');
    const [newsDate, setNewsDate] = useState('');
    const [newsImage , setNewsImage] = useState([]);

    const navigate = useNavigate();

    useEffect(()=>{
        setInitInfo();
        window.scrollTo(0,0);
    },[]);

    const setInitInfo = async()=>{
        const news_no = location.state.no;


        /* 해당기사 조회 */
        const selectNewsDetail = await fetch('http://192.168.0.13:3006/news/selectNewsNo',{
            method : "POST",
            headers : {'Content-Type' : 'application/json'},
            body : JSON.stringify({
                no : news_no
            })
        });

        const newsDetail = await selectNewsDetail.json();
      

        setNewsTitle(newsDetail.newsData[0].news_title);
        setNewsCntn(newsDetail.newsData[0].news_cntn);
        setNewsDate(newsDetail.newsData[0].news_date);
        setNewsImage(newsDetail.image);
    }

    const clickGoback = () =>{
        navigate(-1);
    }

    return(
        <div style={{width:'auto', height:'auto',backgroundColor:'#1e1e1e'}}>
            <Header/>
            <div style={{width:'100%',height:'100%',backgroundColor:'',display:'flex', flexDirection:'column',justifyContent:'center',alignItems:'center', paddingTop:'80px'}}>
                <div style={{width:'1050px',backgroundColor:''}}>
                    <div style={{width:'100%', backgroundColor:'',marginTop:'50px'}}>
                        <span style={{fontSize:'20px', color:'white'}}>{newsTitle}</span>
                        <div style={{width:'100%',backgroundColor:'',display:'flex', justifyContent:'flex-end',alignItems:'flex-end',marginTop:'20px',marginBottom:'20px', color:'white'}}>
                            <span>{moment(newsDate).format('YYYY년 MM월 DD일')}</span>
                        </div>
                        <div style={{width:'100%',height:'1px',border:'1px solid white'}}/>
                    </div>
                    <div style={{width:'100%',height:'100%',backgroundColor:'',marginTop:'100px', display:'flex',flexDirection:'column',justifyContent:'flex-start', alignItems:'flex-start'}}>
                        {newsImage.length != 0 &&
                            <img src={newsImage[0]} width={'100%'}/>
                        }
                        {newsImage.length !=0 &&
                            <img src={newsImage[1]} width={'100%'}/>
                        }
                        <p style={{fontSize:'15px',fontWeight:'bold', whiteSpace: 'pre-line', padding:10, color:'white'}}>
                        {newsCntn}
                        </p>
                        {newsImage.length !=0 &&
                            <img src={newsImage[2]} width={'100%'}/>
                        }
                    </div>
                    <div style={{width:'100%',height:'100%',backgroundColor:'',marginTop:'150px', display:'flex',flexDirection:'column',justifyContent:'center', alignItems:'center',marginBottom:'200px'}}>
                        <div onClick={clickGoback} style={{width:'100px',height:'50px',backgroundColor:'black',color:'white',borderRadius:10 , display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer'}}>뒤로가기</div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}