import { useEffect, useState, useRef, createRef, cloneElement } from 'react';
import Footer from '../layout/footer';
import Header from '../layout/header';
import { useMediaQuery } from 'react-responsive';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import Company_isMobileL from './company/company_isMobileL';
import Company_isLapTop from './company/company_isLapTop';
import Company_isTablet from './company/company_isTablet';
import Company_default from './company/company_default';



export default function Company(props){
    const [position , setPosition ] = useState(0);
    const [currentPage , setCurrentPage] = useState(1); //현재페이지수
    const [newsList, setNewsList] = useState([]); //뉴스리스트
    const [blockNews , setBlockNews] = useState([]);
    const [totalPageLength , setTotalPageLength] = useState(0);// 총 페이지 수
    const [prevArrow , setPrevArrow] = useState(false); //이전화살표 표시여부
    const [nextArrow , setNextArrow] = useState(true); //다음화살표 표시여부

    const [headerVisible , setHeaderVisible] = useState(true);
    let prevScroll = 0;

    const navigate = useNavigate();
    /* responsive area ==== start ==== */
    const isTablet = useMediaQuery({query:'(max-width:768px)'});
    //const isMobileS =  useMediaQuery({query:'(max-width:320px)'});
    //const isMobileM = useMediaQuery({query:'(max-width:375px)'});
    const isMobileL = useMediaQuery({query:'(max-width:425px)'});
    const isLapTop = useMediaQuery({query:'(max-width:1024px)'});
   // const isLapTopL = useMediaQuery({query:'(max-width:1440px)'});
    //const is4K = useMediaQuery({query:'(max-width:2560px)'});
    //const isFold = useMediaQuery({query:'(max-width:280px)'});
    /* responsive area ==== end ==== */

    const companyText1 = useRef();
    const companyText2 = useRef();
    const companyText3 = useRef();
    const companyText4 = useRef();
    const companyText5 = useRef();
    const companyText6 = useRef();
    const companyText7 = useRef();
    const companyText8 = useRef();
    const companyText9 = useRef();
    const companyText10 = useRef();
    const companyText11 = useRef();
    const companyText12 = useRef();
    const companyText13 = useRef();
    const companyText14 = useRef();
    const companyText15 = useRef();
    const companyText16 = useRef();
    const companyText17 = useRef();
    const companyText18 = useRef();
    const companyText19 = useRef();
    const companyText20 = useRef();
    const companyText21 = useRef();
    const companyText22 = useRef();
    const companyText23 = useRef();

    const mobile_text1 = useRef();
    const mobile_text2 = useRef();
    const mobile_text3 = useRef();

    const title1 = useRef();
    const title2 = useRef();
    const title3 = useRef();

    const news1 = useRef();
    const news2 = useRef();

    useEffect(()=>{
        window.scrollTo(0,0);

        const sectionId = window.location.hash.slice(1);
        if(sectionId){
            const element = document.getElementById(sectionId.split('#')[1]);
            if(element){
                element.scrollIntoView({behavior:'smooth'});
            }
        }

        mobile_text1.current.style.opacity = 1;
        mobile_text2.current.style.opacity = 1;

        if(isMobileL){
            mobile_text3.current.style.opacity = 1;
        }
       

        title1.current.style.opacity = 1;
        title2.current.style.opacity = 1;
        title3.current.style.opacity = 1;
        
        
        setIntiInfo();
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };

       

    },[]);

    const setIntiInfo = async()=>{
        /* 뉴스 검색 */
        const selectNews = await fetch('http://192.168.0.13:3006/news/selectAllNews',{
            method : "POST",
            headers : {'Content-Type' : 'application/json'}
        });

        const news = await selectNews.json();
        setNewsList(news);
        /*
         페이지 수 나누기 (한 페이지당 4개씩) 
            페이지수 = 총길이 / 4
        */
        const pageLength = Math.ceil(newsList.length/4); // 나눠서 올림  = 전체 페이지 수
        setTotalPageLength(pageLength); // 전체페이지수 
        if(news.length <= 4){ //총 페이지수가 1인경우 다음 화살표 표시 안함
            setNextArrow(false);
        }

        let block = [];
        for(let a=0; a < 4; a++){
            if(a === news.length){
                break;
            }
            block.push(news[a]);
        }
        setBlockNews(block);
    }

    const onScroll = ()=>{
        const currentScroll = window.scrollY;
        setPosition(currentScroll);

        if(currentScroll < prevScroll){
            setHeaderVisible(true);
        }else{
            setHeaderVisible(false);
        }
        prevScroll = currentScroll;

    }

    const clickNews = (value)=>{
        /*current count 의 기본값 = 1
            클릭했을때 next일경우 기본 currentcount + 1
                       before일경우 기본 currentcount - 1
        */
        let block = [];
        if(value === 'next'){
            setPrevArrow(true); //다음화살표 클릭시 이전화살표 띄워
            setCurrentPage(currentPage+1);

            const count = (currentPage +1 )*4;  //다음페이지마지막 인덱스
            for(let a=(count-4); a<count; a++){
                if(a === newsList.length){
                    break;
                }
                block.push(newsList[a]);
            }

            if(currentPage +1 > totalPageLength){
                setNextArrow(false);
            }

            setBlockNews(block);

        }else if(value === 'before'){
            setNextArrow(true);
            setCurrentPage(currentPage-1);

            const count = (currentPage-1 ) *4; //다음페이지마지막 인덱스
            for(let a=(count-4); a<count; a++){
                block.push(newsList[a]);
            }
            if(currentPage - 1 === 1){
                setPrevArrow(false);
            }
            setBlockNews(block);

        }
    }

    const clickNewsDetail =  async(no)=>{
        navigate('/newsDetail',{state:{no:no}});
    }

    return(
        <div style={{width:'auto',height:'auto'}}>
            {/* {headerVisible && <Header/>} */}
            <Header/>
            {isMobileL ? 
                <Company_isMobileL position={position} title1={title1} title2={title2} title3={title3} mobile_text1={mobile_text1} mobile_text2={mobile_text2} companyText3={companyText3} companyText4={companyText4} companyText5={companyText5} companyText6={companyText6} companyText7={companyText7} companyText8={companyText8} companyText14={companyText14} companyText19={companyText19} companyText20={companyText20} companyText21={companyText21} companyText22={companyText22} mobile_text3={mobile_text3} news1={news1} newsList={newsList} blockNews={blockNews} nextArrow={nextArrow} prevArrow={prevArrow} clickNews={clickNews} clickNewsDetail={clickNewsDetail}/>
            :
                <>
                {isTablet ? 
                    <Company_isTablet position={position} title1={title1} title2={title2} title3={title3} mobile_text1={mobile_text1} mobile_text2={mobile_text2} companyText3={companyText3} companyText4={companyText4} companyText5={companyText5} companyText6={companyText6} companyText7={companyText7} companyText8={companyText8} companyText14={companyText14} companyText19={companyText19} companyText20={companyText20} companyText21={companyText21} companyText22={companyText22} news1={news1}  newsList={newsList} blockNews={blockNews} nextArrow={nextArrow} prevArrow={prevArrow} clickNews={clickNews} clickNewsDetail={clickNewsDetail}/>
                :
                    <>
                    {isLapTop ? 
                        <Company_isLapTop position={position} title1={title1} title2={title2} title3={title3} mobile_text1={mobile_text1} mobile_text2={mobile_text2} companyText3={companyText3} companyText4={companyText4} companyText5={companyText5} companyText6={companyText6} companyText7={companyText7} companyText8={companyText8} companyText14={companyText14} companyText19={companyText19} companyText20={companyText20} companyText21={companyText21} companyText22={companyText22} news1={news1}  newsList={newsList} blockNews={blockNews} nextArrow={nextArrow} prevArrow={prevArrow} clickNews={clickNews} clickNewsDetail={clickNewsDetail}/>
                    :
                        <Company_default position={position} title1={title1} title2={title2} title3={title3} mobile_text1={mobile_text1} mobile_text2={mobile_text2} companyText3={companyText3} companyText4={companyText4} companyText5={companyText5} companyText6={companyText6} companyText7={companyText7} companyText8={companyText8} companyText14={companyText14} companyText19={companyText19} companyText20={companyText20} companyText21={companyText21} companyText22={companyText22} news1={news1}  newsList={newsList} blockNews={blockNews} nextArrow={nextArrow} prevArrow={prevArrow} clickNews={clickNews} clickNewsDetail={clickNewsDetail}/>
                    }
                    </>
                }
                </>
            }    
            
            <Footer/>
        </div>
    )
}