import { useEffect, useState, useRef, createRef, cloneElement} from 'react';
import logo from '../../assets/glins.png';
import { FloatingLabel,Form,Button, Dropdown,Table, Modal } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom'

export default function Ad_login(){

    const navigate = useNavigate();
    const [id, setId] = useState('');
    const [pwd , setPwd] = useState('');

    const clickLogin = async()=>{
        const checkLogin = await fetch('http://192.168.0.13:3006/user/login',{
            method : "POST",
            headers : {'Content-Type' : 'application/json'},
            body : JSON.stringify({
                id : id,
                pwd : pwd
            })
        });

        const login = await checkLogin.json();
        if(login){
            alert('로그인되었습니다');
            navigate('/adminMain');
        }else{
            alert('정보가 일치하지 않습니다');
        }
    }


    return(
        <div style={{width:'auto', height:'auto'}}>
            <div style={{width :'100%', height:'100vh', backgroundColor:'', display:'flex', justifyContent:'center',alignItems:'center'}}>
                <div style={{width:'50%', height:'50%', backgroundColor:'', display:'flex', justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
                    <div style={{width:'20%',height:'20%',backgroundColor:''}}>
                        <img src={logo} width={'100%'} height={'100%'}/>
                    </div>
                    <div style={{width:'20%',height:'10%',backgroundColor:'', display:'flex',justifyContent:'space-between', alignItems:'center'}}>
                        <span style={{}}>아이디</span>
                        <input type={'text'} value={id} onChange={(e)=>{setId(e.target.value)}} style={{borderRadius:5, boxShadow:'1px 1px 1px 1px #D3D3D3', width:'180px' ,height:'20px'}}/>
                    </div>
                    <div style={{width:'20%',height:'10%',backgroundColor:'', display:'flex',justifyContent:'space-between', alignItems:'center'}}>
                        <span style={{}}>비밀번호</span>
                        <input type={'text'} value={pwd} onChange={(e)=>{setPwd(e.target.value)}} style={{borderRadius:5, boxShadow:'1px 1px 1px 1px #D3D3D3', width:'180px' ,height:'20px'}}/>
                    </div>
                    <div style={{width:'20%',height:'10%',backgroundColor:'', display:'flex',justifyContent:'center', alignItems:'center',marginTop:'50px'}}>
                        <Button variant='success' onClick={clickLogin} style={{width:'200px', height:'30px', backgroundColor:'', borderRadius:5}}>로그인</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}