import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes,Route, HashRouter} from 'react-router-dom'
import Main from './view/main';
import Tech from './view/tech';
import Company from './view/company';
import NewsDetail from './view/news/newsDetail';
import Header from './layout/header';
import AdminLogin from './view/admin/ad_login';
import AdminMain from './view/admin/ad_main';
import AdminHeader from './view/admin/layout/ad_header';

function App() {
  return (
      <HashRouter>
        <Routes>
          <Route path='/' element={<Main/>}/>
          <Route path='tech' element={<Tech/>}/>
          <Route path='company' element={<Company/>}/>
          <Route path='newsDetail' element={<NewsDetail/>}/>
          <Route path='header' element={<Header/>}/>
          <Route path='adminLogin' element={<AdminLogin/>}/>
          <Route path='adminMain' element={<AdminMain/>}/>
          <Route path='adminHeader' element={<AdminHeader/>}/>
        </Routes>
      </HashRouter>
  );
}

export default App;
