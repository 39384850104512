import { useEffect, useState, useRef, createRef, cloneElement } from 'react';
import { Link as RouterLink, useNavigate} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import logo from '../assets/glins.png';
import { Link as ScrollLink, Element, Events, animateScroll as scroll, scrollSpy, scroller  } from 'react-scroll';

export default function Header(props){

    const isMobile = useMediaQuery({query:'(max-width:768px)'});
    const navigate = useNavigate();

    const [mobile, setMobile] = useState(false);

    const battery = useRef();
    const company = useRef();
    const icon = useRef();
    const mobileMenu = useRef();

    const clickMenu = (menu)=>{
        if(menu === 'tech'){
            battery.current.style.display= 'flex';
            company.current.style.display= 'none';
            // icon.current.style.display = 'none';
        }else if(menu === 'company'){
            battery.current.style.display= 'none';
            company.current.style.display= 'flex';
            // icon.current.style.display = 'none';
        }else if(menu === 'icon'){
            battery.current.style.display= 'none';
            company.current.style.display= 'none';
            // icon.current.style.display = 'flex';
        }
    }

    const mouseLeave = ()=>{
        battery.current.style.display= 'none';
        company.current.style.display= 'none';
        // icon.current.style.display = 'none';
    }

    const clickMenu_mobile = (flag)=>{
        if(flag){
            mobileMenu.current.style.display = 'flex';
            mobileMenu.current.style.transform =  `translateX(0px)`;
            mobileMenu.current.style.transition = 'all 1s fade-out';
        }else{
            mobileMenu.current.style.display = 'flex';
            mobileMenu.current.style.transform =  `translateX(1000px)`;
            mobileMenu.current.style.transition = 'all 1s fade-out';
        }

        setMobile(flag);
       
    }


    const clickMoveTech = (id)=>{
        navigate(`/tech#${id}`);
    }

    const clicMoveCompany = (id)=>{
        navigate(`/company#${id}`);
    }

    return(
        <div className='all' style={{width:'auto',height:'auto'}}>
            <div className='allSub' style={{width:'auto',height:'auto'}}>
                {isMobile ?
                    <>
                    <div className='mainLayout' style={{width:'100%',height:'80px',position:'relative', display:'flex', justifyContent:'center',alignItems:'center', backgroundColor:'white', zIndex:9999}}>
                        <div className='subLayout' style={{width:'100%',height:'100%',display:'flex',justifyContent:'space-between',alignItems:'center',color:'white',backgroundColor:''}}>
                            <div className='leftLayout' style={{width:'35%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:''}}>
                                <RouterLink to='/'><img src={logo} width={'100%'}/></RouterLink>
                            </div>  
                            <div className='rightLayout' style={{position:'relative',width:'50px',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'',marginRight:'10px'}}>
                                <a onMouseUp={()=>{clickMenu_mobile(!mobile)}} style={{width:'100%',height:'80%',display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'column',backgroundColor:'', cursor:'pointer'}}>
                                    <span style={{borderBottom: '3px solid black', borderBottomWidth:1, width:'80%', height:'10%',margin:0}}/>
                                    <span style={{borderBottom: '3px solid black', borderBottomWidth:1, width:'80%', height:'10%',margin:0}}/>
                                    <span style={{borderBottom: '3px solid black', borderBottomWidth:1, width:'80%', height:'10%',margin:0}}/>
                                </a>
                            </div>
                        </div>  
                    </div>
                    <div ref={mobileMenu} style={{position:'fixed',width:'100%',height:'100%', backgroundColor:'black', display:'none',flexDirection:'column', zIndex:9999,opacity:0.9, transform:`translateX(-1000px)`}}>
                        <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column' ,justifyContent:'flex-start', alignItems:'flex-start'}}>
                            <div style={{width:'300px',height:'200px', backgroundColor:'',display:'flex',flexDirection:'column' ,justifyContent:'flex-start', alignItems:'flex-start',color:'white'}}>
                                {/* <ScrollLink activeClass="active" to="scroll-to-element" spy={true} smooth={true} offset={50} duration={500}><a style={{padding:10, color:'white',fontSize:'20px',fontWeight:'bolder', cursor:'pointer'}}>Tech</a></ScrollLink> */}
                                <RouterLink to='/tech' style={{marginTop :'20px'}}><a style={{padding:10, color:'white',fontSize:'25px',fontWeight:'bolder', cursor:'pointer'}}>Tech</a></RouterLink>
                                <RouterLink to='/tech#tech_app'><a style={{padding:5, color:'white',fontSize:'18px',fontWeight:'normal', cursor:'pointer',marginTop:'10px',marginLeft:'5px'}}>T-ECU</a></RouterLink>
                                <a style={{padding:5, color:'white',fontSize:'18px',fontWeight:'normal', cursor:'pointer',marginLeft:'5px'}}>App</a>
                                {/* <a style={{padding:10, color:'white',fontSize:'18px',fontWeight:'normal', cursor:'pointer'}}>이동할곳3</a> */}
                            </div>
                            <div style={{width:'300px',height:'200px', backgroundColor:'',display:'flex',flexDirection:'column' ,justifyContent:'flex-start', alignItems:'flex-start'}}>
                            <RouterLink to='/company'><a style={{padding:10, color:'white',fontSize:'25px',fontWeight:'bolder', cursor:'pointer'}}>Company</a></RouterLink>
                                <a style={{padding:5, color:'white',fontSize:'18px',fontWeight:'normal', cursor:'pointer', marginTop:'10px', marginLeft:'5px'}}>About GLINS</a>
                                <a style={{padding:5, color:'white',fontSize:'18px',fontWeight:'normal', cursor:'pointer', marginLeft:'5px'}}>News</a>
                                <a style={{padding:5, color:'white',fontSize:'18px',fontWeight:'normal', cursor:'pointer', marginLeft:'5px'}}>Contact</a>
                            </div>
                        </div>
                    </div>  
                    </>
                    
                :
                    <div className='mainLayout' style={{width:'100%',height:'80px',position:'fixed', display:'flex', justifyContent:'center',alignItems:'center', backgroundColor:'white', zIndex:9999}}>
                        <div className='subLayout' style={{width:'1500px',height:'100%',display:'flex',justifyContent:'space-between',alignItems:'center',color:'white', backgroundColor:''}}>
                            <div className='leftLayout' style={{width:'10%',height:'100%',display:'flex',justifyContent:'flex-start',alignItems:'center',backgroundColor:'', marginLeft:'100px'}}>
                                <RouterLink to='/'><img src={logo} width={'100%'}/></RouterLink>
                            </div>  
                            <div className='rightLayout' style={{position:'relative',width:'400px',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'',marginRight:'100px'}}>
                                <div className='mainMenuLayout' style={{width:'60%',height:'100%',display:'flex',justifyContent:'space-between',alignItems:'center',backgroundColor:'',margin:'20px'}}>
                                    <RouterLink to='/tech'><a onMouseOver={()=>{clickMenu('tech')}}  href='#' style={{color:'black',fontWeight:'bolder',fontSize:'18px'}}>Tech</a></RouterLink>
                                    <RouterLink to='/company'><a onMouseOver={()=>{clickMenu('company')}}   href='#' style={{color:'black',fontWeight:'bolder',fontSize:'18px'}}>COMPANY</a></RouterLink>
                                    {/* <a onMouseOver={()=>{clickMenu('icon')}}  href='#' style={{color:'black',fontWeight:'bolder',fontSize:'18px'}}>아이콘1</a> */}
                                </div>
                               
                                <div onMouseOver={()=>{clickMenu('tech')}} onMouseLeave={mouseLeave} ref={battery}  style={{position:'absolute',width:'250px',height:'200px',backgroundColor:'black',top:'60px', left:'80px', display:'none',justifyContent:'flex-start',alignItems:'flex-start', flexDirection:'column',opacity:'0.5'}}>
                                    {/* <RouterLink to='/tech#techApp' style={{cursor:'pointer', fontWeight:'400', fontSize:'20px',padding:10, marginTop:'40px'}}><a style={{color:'white'}}>T-ECU</a></RouterLink> */}
                                    <RouterLink to='/tech' style={{cursor:'pointer', fontWeight:'400', fontSize:'20px',padding:10, marginTop:'40px'}}><a href='#' style={{color : 'white'}}>T-ECU</a></RouterLink>
                                    <a style={{color:'white', fontWeight:'400', fontSize:'20px',padding:10, cursor:'pointer'}} onClick={()=>{clickMoveTech('techApp')}}>App</a>
                                    {/* <a style={{color:'white', fontWeight:'400', fontSize:'20px',padding:10, cursor:'pointer'}}>이동할곳2</a> */}
                                </div>
                                <div onMouseOver={()=>{clickMenu('company')}} onMouseLeave={mouseLeave} ref={company} style={{position:'absolute',width:'220px',height:'200px',backgroundColor:'black',top:'60px', left:'230px', display:'none',justifyContent:'flex-start',alignItems:'flex-start', flexDirection:'column',opacity:'0.5'}}>
                                    {/* <a style={{color:'white', fontWeight:'400', fontSize:'20px',padding:10, marginTop:'40px', cursor:'pointer'}}>About GLINS</a> */}
                                    <a style={{color:'white', fontWeight:'400', fontSize:'20px',padding:10, cursor:'pointer', marginTop:'40px'}} onClick={()=>{clicMoveCompany('news')}}>News</a>
                                    <a style={{color:'white', fontWeight:'400', fontSize:'20px',padding:10, cursor:'pointer'}} onClick={()=>{clicMoveCompany('contact')}}>Contact</a>
                                </div>
                            </div>
                        </div>  
                    </div>
                }
            </div>
        </div>
    )
}