import { useEffect, useState, useRef, createRef, cloneElement } from 'react';
import { useMediaQuery } from 'react-responsive';
import logo from '../assets/glins.png';

export default function Footer(){

    /* responsive area ==== start ==== */
    const isTablet = useMediaQuery({query:'(max-width:768px)'});
    const isMobileS =  useMediaQuery({query:'(max-width:320px)'});
    const isMobileM = useMediaQuery({query:'(max-width:375px)'});
    const isMobileL = useMediaQuery({query:'(max-width:425px)'});
    const isLapTop = useMediaQuery({query:'(max-width:1024px)'});
    const isLapTopL = useMediaQuery({query:'(max-width:1440px)'});
    const is4K = useMediaQuery({query:'(max-width:2560px)'});
    const isFold = useMediaQuery({query:'(max-width:280px)'});
    /* responsive area ==== end ==== */

    return(
        <div style={{width:'auto',height:'auto'}}>
            <div style={{width:'auto',height:'auto'}}>
                {isMobileL ?
                    <div style={{width:'100%',position:'relative', display:'flex', justifyContent:'center',alignItems:'center', backgroundColor:'white',color:'black'}}>
                        <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column', marginTop:'40px', marginBottom:'40px'}}>
                            <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'row'}}>
                                {/* <span style={{marginRight:'20px'}}>본사 / 기업부설연구소 </span> */}
                                <img src={logo} width={'30%'}/>
                            </div>
                            <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'row'}}>
                                {/* <span style={{marginRight:'20px'}}>본사 / 기업부설연구소 </span> */}
                                <span style={{padding:5 ,fontSize:'10px'}}>대전광역시 동구 한밭대로 1232, GLINS (본사/기업부설연구소)</span>
                            </div>
                            <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'column'}}>
                                {/* <span style={{marginRight:'20px'}}>모빌리티 센터</span> */}
                                <span style={{paddingTop :10, fontSize:'10px'}}>충청남도 아산시 탕정면 선문로254번길 12, </span>
                                <span style={{paddingBottom:5,fontSize:'10px'}}>한국자동차연구원 강소특구 제1캠퍼스 315호 (모빌리티센터)</span>
                            </div>
                            <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'row'}}>
                                {/* <span style={{marginRight:'20px'}}>전동화 센터</span> */}
                                <span style={{padding:5 ,fontSize:'10px'}}>대구광역시 북구 호암로 51, 삼성창조캠퍼스 3층 (전동화센터) </span>
                            </div>
                            {/* <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'row',marginTop:'40px',color:'white'}}>
                                <span>개인정보 처리방침</span>
                                <span style={{marginLeft:'20px',marginRight:'20px'}}> | </span>
                                <span>저작권 안내</span>
                            </div> */}
                            <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'row',marginTop:'10px',color:'black'}}>
                                <span style={{fontSize:'10px'}}>042-933-9685</span>
                                <span style={{marginLeft:'20px',marginRight:'20px'}}> | </span>
                                <span style={{fontSize:'10px'}}>070-7543-1354 </span>
                                <span style={{marginLeft:'20px',marginRight:'20px'}}> | </span>
                                <span style={{fontSize:'10px'}}>ceo@corp-gl.com </span>
                            </div> 
                            <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'row',marginTop:'10px',color:'black' , fontSize:'10px'}}>
                                <span>Copyright ⓒ GLINS. All right reserved.</span>
                            </div> 
                        </div>
                    </div>
                :
                    <>
                    {isTablet ? 
                        <div style={{width:'100%',position:'relative', display:'flex', justifyContent:'center',alignItems:'center', backgroundColor:'white',color:'black'}}>
                            <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column', marginTop:'40px', marginBottom:'40px'}}>
                                <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'row'}}>
                                    {/* <span style={{marginRight:'20px'}}>본사 / 기업부설연구소 </span> */}
                                    <img src={logo} width={'30%'}/>
                                </div>
                                <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'row'}}>
                                    {/* <span style={{marginRight:'20px'}}>본사 / 기업부설연구소 </span> */}
                                    <span style={{padding:5 ,fontSize:'11px'}}>대전광역시 동구 한밭대로 1232, GLINS (본사/기업부설연구소)</span>
                                </div>
                                <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'column'}}>
                                    {/* <span style={{marginRight:'20px'}}>모빌리티 센터</span> */}
                                    <span style={{paddingTop:5 ,fontSize:'11px'}}>충청남도 아산시 탕정면 선문로254번길 12,</span>
                                    <span style={{paddingBottom:5 ,fontSize:'11px'}}>한국자동차연구원 강소특구 제1캠퍼스 315호 (모빌리티센터)</span>
                                </div>
                                <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'row'}}>
                                    {/* <span style={{marginRight:'20px'}}>전동화 센터</span> */}
                                    <span style={{padding:5 ,fontSize:'11px'}}>대구광역시 북구 호암로 51, 삼성창조캠퍼스 3층 (전동화센터) </span>
                                </div>
                                {/* <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'row',marginTop:'40px',color:'white'}}>
                                    <span>개인정보 처리방침</span>
                                    <span style={{marginLeft:'20px',marginRight:'20px'}}> | </span>
                                    <span>저작권 안내</span>
                                </div> */}
                                <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'row',marginTop:'10px',color:'black'}}>
                                    <span style={{fontSize:'13px'}}>042-933-9685</span>
                                    <span style={{marginLeft:'20px',marginRight:'20px'}}> | </span>
                                    <span style={{fontSize:'13px'}}>070-7543-1354 </span>
                                    <span style={{marginLeft:'20px',marginRight:'20px'}}> | </span>
                                    <span style={{fontSize:'13px'}}>ceo@corp-gl.com </span>
                                </div> 
                                <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'row',marginTop:'10px',color:'black' , fontSize:'13px'}}>
                                    <span>Copyright ⓒ GLINS. All right reserved.</span>
                                </div> 
                            </div>
                        </div>
                    :
                        <div style={{width:'100%',position:'relative', display:'flex', justifyContent:'center',alignItems:'center', backgroundColor:'white',color:'black'}}>
                            <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column', marginTop:'40px', marginBottom:'40px'}}>
                                <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'row'}}>
                                    {/* <span style={{marginRight:'20px'}}>본사 / 기업부설연구소 </span> */}
                                    <img src={logo} width={'5%'}/>
                                </div>
                                <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'row'}}>
                                    {/* <span style={{marginRight:'20px'}}>본사 / 기업부설연구소 </span> */}
                                    <span style={{padding:5 ,fontSize:'13px'}}>대전광역시 동구 한밭대로 1232, GLINS (본사/기업부설연구소) </span>
                                </div>
                                <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'column'}}>
                                    {/* <span style={{marginRight:'20px'}}>모빌리티 센터</span> */}
                                    <span style={{paddingTop:5 ,fontSize:'13px'}}>충청남도 아산시 탕정면 선문로254번길 12</span>
                                    <span style={{paddingBottom:5 ,fontSize:'13px'}}>한국자동차연구원 강소특구 제1캠퍼스 315호 (모빌리티센터)</span>
                                </div>
                                <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'row'}}>
                                    {/* <span style={{marginRight:'20px'}}>전동화 센터</span> */}
                                    <span style={{padding:5 ,fontSize:'13px'}}>대구광역시 북구 호암로 51, 삼성창조캠퍼스 3층 (전동화센터) </span>
                                </div>
                                {/* <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'row',marginTop:'40px',color:'white'}}>
                                    <span>개인정보 처리방침</span>
                                    <span style={{marginLeft:'20px',marginRight:'20px'}}> | </span>
                                    <span>저작권 안내</span>
                                </div> */}
                                <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'row',marginTop:'10px',color:'black'}}>
                                    <span style={{fontSize:'13px'}}>042-933-9685</span>
                                    <span style={{marginLeft:'20px',marginRight:'20px'}}> | </span>
                                    <span style={{fontSize:'13px'}}>070-7543-1354 </span>
                                    <span style={{marginLeft:'20px',marginRight:'20px'}}> | </span>
                                    <span style={{fontSize:'13px'}}>ceo@corp-gl.com </span>
                                </div> 
                                <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'row',marginTop:'10px',color:'black' , fontSize:'13px'}}>
                                    <span>Copyright ⓒ GLINS. All right reserved.</span>
                                </div> 
                            </div>
                        </div>
                    }
                    </>
                    
                }
                
            </div>
        </div>
    )
}